import axios from '@/libs/axios'
import endpoints from '@/http/endpoints'

export default {

  // #region Vendor
  async addVendor(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.printerServerManagement.Controller.addVendor(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async deleteVendor(id) {
    const promise = new Promise((resolve, reject) => {
      axios.delete(endpoints.printerServerManagement.Controller.deleteVendor(id))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async editVendor(id, data) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.printerServerManagement.Controller.editVendor(id), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getVendors() {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.printerServerManagement.Controller.getVendors())
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion
  // #region Labels
  async getLabels(printerVendorid) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.printerServerManagement.Controller.getLabels(printerVendorid))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async addLabel(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.printerServerManagement.Controller.addLabel(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async editLabel(labelid, data) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.printerServerManagement.Controller.editLabel(labelid), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async deleteLabel(labelid) {
    const promise = new Promise((resolve, reject) => {
      axios.delete(endpoints.printerServerManagement.Controller.deleteLabel(labelid))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion

  // #region SystemData
  async getSystemData() {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.printerServerManagement.Controller.getSystemData())
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getSystemDataByKey(key) {
    const result = await axios.get(endpoints.printerServerManagement.Controller.getSystemDataByKey(key))
    return result.data.body
  },
  async addSystemData(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.printerServerManagement.Controller.addSystemData(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async editSystemData(systemdataid, data) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.printerServerManagement.Controller.editSystemData(systemdataid), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async deleteSystemData(systemdataid) {
    const promise = new Promise((resolve, reject) => {
      axios.delete(endpoints.printerServerManagement.Controller.deleteSystemData(systemdataid))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion
}
