import { ref, reactive } from '@vue/composition-api'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'

const itemCounts = ref([])
const actionCounts = ref([])
const openBatches = ref([])
const openBatchCounts = reactive({
  'WFS Open': 0,
  'WFS Boxing': 0,
  'FBA Open': 0,
  'FBA Boxing': 0,
})

export default function useStatisticsCardState() {
  function countCombinations(data) {
    const counts = data.reduce((accumulator, item) => {
      const key = `${item.channel}-${item.batchStatus}`
      // eslint-disable-next-line no-param-reassign
      accumulator[key] = (accumulator[key] || 0) + 1
      return accumulator
    }, {})

    return {
      'fba-open': counts['fba-open'] || 0,
      'fba-boxing': counts['fba-boxing'] || 0,
      'wfs-open': counts['wfs-open'] || 0,
      'wfs-boxing': counts['wfs-boxing'] || 0,
    }
  }

  async function fetchOpenBatches(channel) {
    openBatches.value = await outboundShippingService.getOpenParentBatches(channel)
    const counts = countCombinations(openBatches.value)
    openBatchCounts['WFS Open'] = counts['wfs-open']
    openBatchCounts['WFS Boxing'] = counts['wfs-boxing']
    openBatchCounts['FBA Open'] = counts['fba-open']
    openBatchCounts['FBA Boxing'] = counts['fba-boxing']
  }

  return {
    itemCounts,
    actionCounts,
    openBatches,
    fetchOpenBatches,
    openBatchCounts,
  }
}
