/* eslint-disable lines-between-class-members */
class WalmartAuthorization {
  /** @type {!number} */
  #id = null;
  /** @type {?number} */
  #storeId = 0;
  /** @type {?string} */
  #partnerId = '';
  /** @type {?string} */
  #apiAuthCode = '';
  /** @type {?string} */
  #refreshToken = '';
  /** @type {?string} */
  #accessToken = '';
  /** @type {?string} */
  #tokenType = '';
  /** @type {?number} */
  #accessTokenExpiresAt = null;
  /** @type {?string} */
  #restrictedToken = '';
  /** @type {?number} */
  #restrictedTokenExpiresAt = null;

  constructor() {
    this.#id = this.id
    this.#storeId = this.storeId
    this.#partnerId = this.partnerId
    this.#apiAuthCode = this.apiAuthCode
    this.#refreshToken = this.refreshToken
    this.#accessToken = this.accessToken
    this.#tokenType = this.tokenType
    this.#accessTokenExpiresAt = this.accessTokenExpiresAt
    this.#restrictedToken = this.restrictedToken
    this.#restrictedTokenExpiresAt = this.restrictedTokenExpiresAt
  }

  walmartAuthorizationDto() {
    return {
      id: this.#id,
      storeId: this.#storeId,
      partnerId: this.#partnerId,
      apiAuthCode: this.#apiAuthCode,
      refreshToken: this.#refreshToken,
      accessToken: this.#accessToken,
      tokenType: this.#tokenType,
      accessTokenExpiresAt: this.#accessTokenExpiresAt,
      restrictedToken: this.#restrictedToken,
      restrictedTokenExpiresAt: this.#restrictedTokenExpiresAt,
    }
  }

  /** @returns {!number|null} */
  get id() {
    return this.#id
  }
  /** @param {(number|null)} value */
  set id(value) {
    this.#id = value
  }

  /** @returns {number} */
  get storeId() {
    return this.#storeId
  }
  /** @param {number} value */
  set storeId(value) {
    this.#storeId = value
  }

  /** @returns {string} */
  get partnerId() {
    return this.#storeId
  }
  /** @param {string} value */
  set partnerId(value) {
    this.#partnerId = value
  }

  /** @returns {string} */
  get apiAuthCode() {
    return this.#apiAuthCode
  }
  /** @param {string} value */
  set apiAuthCode(value) {
    this.#apiAuthCode = value
  }

  /** @returns {string} */
  get refreshToken() {
    return this.#refreshToken
  }
  /** @param {string} value */
  set refreshToken(value) {
    this.#refreshToken = value
  }

  /** @returns {string} */
  get accessToken() {
    return this.#accessToken
  }
  /** @param {string} value */
  set accessToken(value) {
    this.#accessToken = value
  }

  /** @returns {string} */
  get tokenType() {
    return this.#tokenType
  }
  /** @param {string} value */
  set tokenType(value) {
    this.#tokenType = value
  }

  /** @returns {?number} */
  get accessTokenExpiresAt() {
    return this.#accessTokenExpiresAt
  }
  /** @param {(number|null)} value */
  set accessTokenExpiresAt(value) {
    this.#accessTokenExpiresAt = value
  }

  /** @returns {?string} */
  get restrictedToken() {
    return this.#restrictedToken
  }
  /** @param {string} value */
  set restrictedToken(value) {
    this.#restrictedToken = value
  }

  /** @returns {?number} */
  get restrictedTokenExpiresAt() {
    return this.#restrictedTokenExpiresAt
  }
  /** @param {(number|null)} value */
  set restrictedTokenExpiresAt(value) {
    this.#restrictedTokenExpiresAt = value
  }
}
export default WalmartAuthorization
