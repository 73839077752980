import {
  ref,
  // reactive, watch, computed,
} from '@vue/composition-api'

const customsItemsState = ref([])
const storeId = ref(null)
const customsDeclarationId = ref(null)

export default function useCustomsItems() {
  function addCustomsItem(payload) {
    customsItemsState.value.push(payload)
  }

  function removeCustomsItem(payload) {
    customsItemsState.value = customsItemsState.value.filter(el => el.productId !== payload.productId && el.sku !== payload.sku)
  }

  function updateCustomsItem(updatedItem) {
    const index = customsItemsState.value.findIndex(
      item => item.productId === updatedItem.productId && item.sku === updatedItem.sku,
    )

    if (index !== -1) {
      customsItemsState.value.splice(index, 1, updatedItem)
    }
  }

  function setStoreId(e) {
    storeId.value = e.value
  }
  return {
    customsItemsState,
    addCustomsItem,
    removeCustomsItem,
    updateCustomsItem,
    setStoreId,
    storeId,
    customsDeclarationId,
  }
}
