<template>
  <div class="card rounded-1 p-1">
    <div class="d-flex justify-content-between">
      <div class="card-group-title  flex-grow-1 text-nowrap">
        Urgent Actions
      </div>
    </div>
    <div class="card-container py-1 px-0 mx-0">
      <div class="full-row">
        <batch-status-action-card />
      </div>
      <div v-for="(item) in computedStatisticsList" :key="item.id" class="item bg-danger">
        <urgent-actions-card :stats="item" class="h-100" />
      </div>
      <open-ltl-references class="full-row" />
      <pending-companies v-if="hasPermission" class="full-row" />
    </div>
  </div>
</template>

<script>
import basicStatsService from '@/http/requests/stats/basicStatsService'
import { v4 as uuidv4 } from 'uuid'
import useCurrentUser from '@/libs/app/current-user'
import UrgentActionsCard from './UrgentActionsCard.vue'
import useStatisticsCardState from './useStatisticsState'
import BatchStatusActionCard from './BatchStatusActionsCard.vue'
import OpenLTLReferences from './OpenLTLReferences.vue'
import PendingCompanies from './PendingCompanies.vue'

export default {
  components: {
    'urgent-actions-card': UrgentActionsCard,
    'batch-status-action-card': BatchStatusActionCard,
    'open-ltl-references': OpenLTLReferences,
    'pending-companies': PendingCompanies,
  },
  data() {
    return {
      statisticsList: [
        {
          id: uuidv4(),
          title: 'FBM Order Actions',
          description: 'Number of recent actions taken on FBM.',
          icon: 'icon-psh-logistic',
          threshold: 99,
          children: [
            {
              id: uuidv4(),
              title: 'Awaiting',
              statsType: 'fbm_awaiting_order_count',
            },
          ],
          permission: 'resource_outbound_fbm_force_main',
          active: true,
          path: 'fbm-force',
        },
        {
          id: uuidv4(),
          title: 'Open Problems',
          description: 'Unresolved issues need prompt action and resolution.',
          icon: 'icon-psh-logistic',
          threshold: 10,
          children: [
            {
              id: uuidv4(),
              title: 'Open',
              statsType: 'problem_open_count',
            },
          ],
          permission: 'null',
          active: !this.isIndividualTenant,
          path: 'problem-management',
        },
      ],
    }
  },
  setup() {
    const {
      actionCounts, openBatches, fetchOpenBatches,
    } = useStatisticsCardState()
    const { isIndividualTenant } = useCurrentUser()
    return {
      actionCounts,
      openBatches,
      fetchOpenBatches,
      isIndividualTenant,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    computedStatisticsList() {
      return this.statisticsList.filter(item => item.active === true)
    },
  },
  async mounted() {
    await this.fetchStatistics()
    await this.fetchOpenBatches()
  },
  methods: {
    async fetchStatistics() {
      const statsTypeArray = this.computedStatisticsList.map(stat => {
        if (stat.children) {
          return stat.children.map(child => child.statsType)
        }
        return stat.statsType
      }).flat()

      const allFilters = statsTypeArray.map(type => ({
        statsType: type,
        accountNo: null,
        companyId: null,
        storeId: null,
        warehouseId: null,
        beginDate: null,
        endDate: null,
        timeInterval: null,
        q: '',
        topN: null,
      }))
      const result = await basicStatsService.getStatsByList(allFilters)
      this.actionCounts = [...result]
    },

  },
}
</script>

<style lang="scss" scoped>
.card-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: 12px;
  align-items: stretch;
}

@media (max-width: 600px) {
  .card-container {
    grid-template-columns: 1fr !important;
  }
}
.full-row {
 grid-column: span 2;
}
.item {
  overflow: hidden;
  justify-self: stretch;
  align-self: stretch;
}

@media (max-width: 1280px) {
  .card-container {
    grid-template-columns: 1fr; /* Change to one column for screen sizes less than 1280px */
  }
  .full-row {
    grid-column: span 1;
  }
}

</style>
