import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  /**
   * Http POST /api/outbound/wfs/auth/create
   * @param data
   * @param data.storeId {number}
   * @param data.partnerId {string}
   * @param data.apiAuthCode {string}
   * @param data.refreshToken {string}
   * @param data.accessToken {string}
   * @param data.tokenType {string}
   * @param data.accessTokenExpiresAt {string}
   * @param data.restrictedToken {string}
   * @param data.restrictedTokenExpiresAt {string}
   * @returns {string}
  */
  async createAuth(data) {
    const result = await axios.post(endpoints.wfs.controller.createAuth(), data)
    return result.data.body
  },
  async createAuthInStoresTab(data) {
    const result = await axios.post(endpoints.wfs.controller.createAuth(), data)
    return result.data.body
  },

  /**
   * Http POST /api/outbound/wfs/auth/create
   * @param data
   * @param data.storeId {number}
   * @param data.partnerId {string}
   * @param data.apiAuthCode {string}
   * @param data.refreshToken {string}
   * @param data.accessToken {string}
   * @param data.tokenType {string}
   * @param data.accessTokenExpiresAt {string}
   * @param data.restrictedToken {string}
   * @param data.restrictedTokenExpiresAt {string}
   * @returns {string}
  */
  async updateAuth(data) {
    const result = await axios.put(endpoints.wfs.controller.updateAuth(), data)
    return result.data.body
  },

  async getAppAuthorization() {
    const result = await axios.get(endpoints.wfs.controller.getAppAuthorization())
    return result.data.body
  },

  /**
   * Http GET /api/outbound/plan/{id}
   * @param id {number}
   * @returns {object}
  */
  async getShipmentPlan(id) {
    const result = await axios.get(endpoints.wfs.controller.getShipmentPlan(id))
    return result.data.body
  },

  /**
   * Http GET /api/outbound/plan/query
   * @param query {object}
   * @param query.accountNo {string}
   * @param query.companyId {number}
   * @param query.storeId {number}
   * @param query.warehouseId {number}
   * @param query.beginDate {string}
   * @param query.endDate {string}
   * @param query.tenantType {string}
   * @param query.q {string}
   * @returns {object}
   */
  async getShimentPlansByQuery(query, pageable) {
    const result = await axios.post(endpoints.wfs.controller.getShipmentPlansByQuery(pageable), query)
    return result.data.body.content
  },

  /**
   * Http GET /api/outbound/plan/{id}/stats
   * @param id {number}
   * @returns {object}
   */
  async getShipmentPlanStats(id) {
    const result = await axios.get(endpoints.wfs.controller.getShipmentPlanStats(id))
    return result.data.body
  },

  /**
   * Http POST /api/outbound/plan/create
   * @param data
   * @param data.creationTime {string}
   * @param data.tenantId {string}
   * @param data.batchName {string}
   * @param data.companyId {number}
   * @param data.storeId {number}
   * @param data.invoiceId {number}
   * @param data.itemCount {number}
   * @param data.batchStatus {string}
   * @param data.amzBatchStatus {string}
   * @param data.channel {string}
   * @param data.packingType {string}
   * @param data.workflow {string}
   * @param data.fromAddress {string}
   * @param data.shipmentType {string}
   * @param data.labelingPreference {string}
   * @param data.serviceChargeType {string}
   * @param data.shipToCountryCode {string}
   * @param data.submittedAt {string}
   * @param data.shipTime {string}
   * @param data.closedAt {string}
   * @param data.carrier {string}
   * @param data.warehouseId {number}
   * @param data.notes {string}
   * @param data.errors {string}
   * @param data.oldBatchId {string}
   * @param data.items {object}
   */
  async createShipmentPlan(data) {
    const result = await axios.post(endpoints.wfs.controller.createShipmentPlan(), data)
    return result.data.body
  },

  /**
   * Http PUT /api/outbound/plan/create
   * @param data
   * @param data.id {number}
   */
  async updateShipmentPlan(data) {
    const result = await axios.put(endpoints.wfs.controller.updateShipmentPlan(), data)
    return result.data.body
  },

  /**
   * Http DELETE /api/outbound/plan/{id}
   * @param id {number}
   * @returns {string}
   */
  async deleteShipmentPlan(id) {
    const result = await axios.delete(endpoints.wfs.controller.deleteShipmentPlan(id))
    return result.data.body
  },

  /**
   * Http PUT /api/outbound/wfs/plan/close/{id}
   * @param id {number}
   */
  closeShipmentPlan(id) {
    return axios.put(endpoints.wfs.controller.closeShipmentPlan(id))
  },

  /**
   * Http POST /api/outbound/plan/items/query
   * @param query {object}
   * @returns {string}
   */
  async getShipmentPlanItemDataByQuery(query) {
    const result = await axios.post(endpoints.wfs.controller.getShipmentPlanItemDataByQuery(), query)
    return result.data.body
  },

  /**
   * Http PUT /api/outbound/plan/submit/{planId}
   * @param planId {number}
   * @returns {string}
   */
  async submitShipmentPlan(planId, deliveryDate) {
    const result = await axios.put(endpoints.wfs.controller.submitShipmentPlan(planId, deliveryDate))
    return result.data.body
  },

  /**
   * Http GET /api/outbound/plan/items/{id}
   * @param id {number}
   * @returns {string}
   */
  async getShipmentPlanItemById(id) {
    const result = await axios.get(endpoints.wfs.controller.getShipmentPlanItemById(id))
    return result.data.body
  },

  /**
   * Http POST /api/outbound/plan/items/create
   * @param data
   */
  async createShipmentPlanItem(data) {
    const result = await axios.post(endpoints.wfs.controller.createShipmentPlanItem(), data)
    return result.data.body
  },

  /**
   * Http PUT /api/outbound/wfs/plan/items/update/quantity
   * @returns {string}
   * @param data {object}
   */
  async updateShipmentPlanItemQuantity(data) {
    const result = await axios.put(endpoints.wfs.controller.updateShipmentPlanItemQuantity(), data)
    return result.data.body
  },

  /**
   * Http PUT /api/outbound/plan/items/update
   */
  async updateShipmentPlanItem(data) {
    const result = await axios.put(endpoints.wfs.controller.updateShipmentPlanItem(), data)
    return result.data.body
  },

  /**
   * Http DELETE /api/outbound/plan/items/{id}
   * @param id {number}
  */
  async deleteShipmentPlanItem(id) {
    const result = await axios.delete(endpoints.wfs.controller.deleteShipmentPlanItem(id))
    return result.data.body
  },

  /**
   * Http GET /api/outbound/plan/batch/{shipmentPlanId}
   * @param shipmentPlanId {number}
   */
  async getBatchDataByShipmentPlanId(shipmentPlanId) {
    const result = await axios.get(endpoints.wfs.controller.getBatchDataByShipmentPlanId(shipmentPlanId))
    return result.data.body
  },

  /**
   * Http POST /api/outbound/plan/items/search
   * @param data
   */
  async searchItem(data) {
    const result = await axios.post(endpoints.wfs.controller.searchItem(), data)
    return result.data.body
  },

  /**
   * Http POST /api/outbound/plan/items/query/batch
   * @param query {object}
   * @returns {object}
   * @param query.shipmentPlanId {number}
   * @param query.batchId {number}
   * @param query.status {string} - 'IN_PREP' | 'PRE_SHIP' | 'READY_TO_SHIP' | 'SHIPPED' | 'DELIVERED' | 'RECONCILED' | 'ALL' | 'NOT_CREATED_YET'
   * @param query.batchStatus {string} - 'all' | 'open' | 'boxing' | 'box_dims' | 'closed' | 'all_but_closed'
   * @param query.amzStatus {string} - 'WORKING' | 'SHIPPED' | 'RECEIVING' | 'CANCELLED' | 'DELETED' | 'CLOSED' | 'ERROR' | 'IN_TRANSIT' | 'DELIVERED' | 'CHECKED_IN'
   * @param query.channel {string} - 'fba' | 'mfn' | 'wfs'
   * @param query.productCode {string}
   */
  async getShipmentPlanItemDataByQueryBatch(query) {
    const result = await axios.post(endpoints.wfs.controller.getShipmentPlanItemDataByQueryBatch(), query)
    return result.data.body
  },

  /**
   * Http GET /api/outbound/wfs/plan/batch/can-transmit-batch/{batchId}
   * @returns {object}
   * @param id {number}
   */
  async canTransmitBatch(id) {
    const result = await axios.get(endpoints.wfs.controller.canTransmitBatch(id))
    return result.data.body
  },

  /**
   * GET /api/outbound/wfs/plan/items/box-map/batch/{batchId}
   * return {object}
   * @param batchId {number}
   */
  async getShipmentPlanItemBoxMapItemByBatch(batchId) {
    const result = await axios.get(endpoints.wfs.controller.getShipmentPlanItemBoxMapItemByBatch(batchId))
    return result.data.body
  },

  /**
   * Http GET /api/outbound/wfs/plan/items/box-map/box/{boxId}
   * @param boxId {number}
   * @returns {object}
   */
  async getShipmentPlanItemBoxMapItemByBox(filter) {
    const result = await axios.post(endpoints.wfs.controller.getShipmentPlanItemBoxMapItemByBox(), filter)
    return result.data.body
  },

  /**
  * Http POST /api/outbound/wfs/plan/items/box/scan-item
  */
  async scanItemMapByCode(data) {
    const result = await axios.post(endpoints.wfs.controller.scanItemMapByCode(), data)
    return result.data.body
  },

  /**
  * Http PUT /api/outbound/wfs/plan/items/box/scan-item
  */
  async updateItemMapQuantity(data) {
    const result = await axios.put(endpoints.wfs.controller.updateItemMapQuantity(), data)
    return result.data.body
  },

  /**
   * Http GET /api/outbound/wfs/plan/batch/box/{id}
   * @param {number} id
   * @returns {object}
   */
  async findBoxById(id) {
    const result = await axios.get(endpoints.wfs.controller.findBoxById(id))
    return result.data.body
  },

  /**
   * Http GET /api/outbound/wfs/plan/batch/box/{batchId}
   * @returns {object}
   * @param batchId {number}
   */
  async findAllBoxesByBatchId(batchId) {
    const result = await axios.get(endpoints.wfs.controller.findAllBoxesByBatchId(batchId))
    return result.data.body
  },

  /**
   * Http POST /api/outbound/wfs/plan/batch/box
   * @param data {object}
   * @param data.id {number | null}
   * @param data.companyId {number}
   * @param data.storeId {number}
   * @param data.warehouseId {number}
   * @param data.batchId {number}
   * @param data.itemAction {string } - 'FBA' | 'FBM' | 'WFS' | 'RETURN' | 'REMOVAL_ORDER' | 'FORWARDING' | 'STORAGE' | 'DAMAGED'
   * @returns {object}
   */
  async createBox(data) {
    const result = await axios.post(endpoints.wfs.controller.createBox(), data)
    return result.data.body
  },

  /**
   * Http PUT /api/outbound/wfs/plan/batch/box
   * @param data {object}
   * @param data.id {number}
   * @param data.length {number}
   * @param data.width {number}
   * @param data.height {number}
   * @param data.weight {number}
   */
  async updateBoxDimensions(data) {
    const result = await axios.put(endpoints.wfs.controller.updateBoxDimensions(), data)
    return result.data.body
  },

  async deleteBatchBoxById(id) {
    const result = await axios.delete(endpoints.wfs.controller.deleteBatchBoxById(id))
    return result.data.body
  },

  async deleteAllBatchBoxesByBatchId(batchId) {
    const result = await axios.delete(endpoints.wfs.controller.deleteAllBatchBoxesByBatchId(batchId))
    return result.data.body
  },
  /**
   * Http POST /api/outbound/wfs/plan/items/box/bulk
   * @param list {array}
   * @param data {object}
   * @param data.id {number}
   * @param data.batchId {number}
   * @param data.itemId {number}
   * @param data.boxId {number}
   * @param data.quantity {number}
   * return {object}
   */
  async assignBulkBoxItemMap(data) {
    const result = await axios.post(endpoints.wfs.controller.assignBulkBoxItemMap(), data)
    return result.data.body
  },

  /**
   * Http POST /api/outbound/wfs/plan/items/box/auto-assign
   * @param data {object}
   * @param data.itemId {number}
   * @param data.quantity {number}
   * @param data.assignmentQuantity {number}
   * @param data.assignmentType {string}
   * @param data.length {number}
   * @param data.width {number}
   * @param data.height {number}
   * @param data.weight {number}
   */

  async autoAssignBoxItemMap(data) {
    const result = await axios.post(endpoints.wfs.controller.autoAssignBoxItemMap(), data)
    return result.data.body
  },

  /**
   * Http DELETE /api/outbound/wfs/plan/items/box/{id}
   * @param id {number}
   */
  async deleteShipmentBoxMapById(id) {
    const result = await axios.delete(endpoints.wfs.controller.deleteShipmentBoxMapById(id))
    return result.data.body
  },

  /**
   * Http DELETE /api/outbound/wfs/plan/items/box/batch/{batchId}
   * @param batchId {number}
   */
  async deleteShipmentBoxMapsByBatch(batchId) {
    const result = await axios.delete(endpoints.wfs.controller.deleteShipmentBoxMapsByBatch(batchId))
    return result.data.body
  },

  /**
   * Http POST /api/outbound/wfs//plan/batch/shipping/label/quote/create
   * @param {*} data
   * @returns {object}
   */
  async getCarrierRateQuote(data) {
    const result = await axios.post(endpoints.wfs.controller.getCarrierRateQuote(), data)
    return result
  },

  /**
   * Http PUT /api/outbound/wfs/plan/batch/shipping/label/quote/{batchId}/confirm
   * @param {number} batchId
   * @returns {object}
   */
  async confirmCarrierRateQuote(batchId) {
    const result = await axios.put(endpoints.wfs.controller.confirmCarrierRateQuote(batchId))
    return result
  },

  /**
   * Http PUT /api/outbound/wfs/plan/batch/shipping/label/quote/{batchId}/void
   * @param {number} batchId
   * @returns {object}
   */
  async voidCarrierRateQuote(batchId) {
    const result = await axios.put(endpoints.wfs.controller.voidCarrierRateQuote(batchId))
    return result
  },

  /**
   * Http GET /api/outbound/wfs/plan/batch/shipping/label/{batchId}/print
   * @param {number} batchId
   * @returns {object}
   */
  async printCarrierLabel(batchId) {
    const result = await axios.get(endpoints.wfs.controller.printCarrierLabel(batchId))
    return result
  },

  /**
   * Http GET /api/outbound/wfs/settings/shipping/template
   * @param storeId {number}
   * @param templateId {number}
   */
  async getShippingTemplateDetails(storeId, templateId) {
    const result = await axios.get(endpoints.wfs.controller.getShippingTemplateDetails(storeId, templateId))
    return result.data.body
  },

  /**
   * Http GET /api/outbound/wfs/settings/shipping/templates
   * @param storeId {number}
   */
  async getShippingTemplates(storeId) {
    const result = await axios.get(endpoints.wfs.controller.getShippingTemplates(storeId))
    return result.data.body
  },

  /**
   * Http POST /api/outbound/wfs/settings/shipping/template
   * @param storeId {number}
   * @param templateId {number}
   */
  async createShippingTemplates(storeId, templateId) {
    const result = await axios.post(endpoints.wfs.controller.createShippingTemplates(storeId, templateId))
    return result.data.body
  },

  /**
   * Http PUT /api/outbound/wfs/settings/shipping/template
   * @param storeId {number}
   * @param templateId {number}
   */
  async updateShippingTemplates(storeId, templateId) {
    const result = await axios.put(endpoints.wfs.controller.updateShippingTemplates(storeId, templateId))
    return result.data.body
  },

  /**
   * Http DELETE /api/outbound/wfs/settings/shipping/template
   * @param storeId {number}
   * @param templateId {number}
   */
  async deleteShippingTemplates(storeId, templateId) {
    const result = await axios.post(endpoints.wfs.controller.deleteShippingTemplates(storeId, templateId))
    return result.data.body
  },

  async updateShipmentPlanItemPlanId(id, planId, applyAll) {
    const result = await axios.put(endpoints.wfs.controller.updateShipmentPlanItemPlanId(id, planId, applyAll))
    return result
  },
}
