<template>
  <div class="card rounded-1 p-1">
    <div class="d-flex justify-content-between">
      <div class="card-group-title  flex-grow-1 text-nowrap">
        Top 10 Most Shipped Products
      </div>
      <dx-util-text-box v-if="hasPermission" v-model="accountNo" class="mr-half" placeholder="Account No" @key-down="onFilterByAccountNo" />
      <date-selection default-date="last90Days" @setdates="onDatesSelection" />
    </div>
    <div class="mt-2">
      <div v-for="(item, idx) in items" :key="idx" class="grid-container my-2">
        <div class="image">
          <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 64px !important; max-height: 72px; margin-right: 0.625rem" class="rounded text-white" @click="showLargeImage(item.largeImage)">
        </div>
        <div class="title">
          <h4 class="text-wrap mb-half my-0">
            {{ item.title }}
          </h4>
          <div class="d-flex">
            <div class="pr-half">
              <small class="text-muted mr-half">UPC: </small>
              <span>{{ item.upc || '-' }}</span>
              <copy-button v-if="item.upc" :copy-value="item.upc" />
            </div>
            <div class="">
              <small class="text-muted mr-half">ASIN: </small>
              <a :href="`https://www.amazon.com/gp/product/${item.asin}`" target="_blank">{{ item.asin || '-' }}</a>
              <copy-button v-if="item.asin" :copy-value="item.asin" />
            </div>
          </div>
        </div>
        <div class="rank text-center px-1">
          <small class="text-nowrap">
            Amazon Rank
          </small>
          <h3>
            {{ item.amzRank|| '-' }}
          </h3>
        </div>
        <div class="count text-center px-1">
          <small>
            Count
          </small>
          <h3>
            {{ formatNumber(item.quantity) }}
          </h3>
        </div>
      </div>
    </div>
    <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
  </div>
</template>

<script>
import basicStatsService from '@/http/requests/stats/basicStatsService'
import CoolLightBox from 'vue-cool-lightbox'
import DateSelection from './DateSelection.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    'date-selection': DateSelection,
    'light-box': CoolLightBox,
  },
  data() {
    return {
      accountNo: '',
      beginDate: '',
      endDate: '',
      items: [],
      index: null,
      images: [],
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  methods: {
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value
          this.fetchStatistics()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = null
        this.fetchStatistics()
      }
    },
    onDatesSelection(e) {
      this.beginDate = e.beginDate
      this.endDate = e.endDate
      this.fetchStatistics()
    },
    async fetchStatistics() {
      const filters = {
        statsType: 'fbm_shipment_top_n_item',
        accountNo: this.accountNo,
        companyId: null,
        storeId: null,
        warehouseId: null,
        beginDate: this.beginDate,
        endDate: this.endDate,
        timeInterval: null,
        q: '',
        topN: 10,
      }
      const result = await basicStatsService.getDashboardStatsTopN(filters)
      this.items = result.data
    },
    showLargeImage(image) {
      this.images.push(image)
      this.index = 0
    },
    formatNumber(number) {
      if (typeof number !== 'number' || Number.isNaN(number)) {
        return '0'
      }
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-container {
 display: grid;
 align-items: center;
 grid-template-columns: auto 1fr auto auto;
 grid-template-areas:
   "image title rank count";
 gap: 4px;
 margin: 2px 2px 2px 2px;
 padding: 2px 2px 8px 2px;
 border-bottom: 1px solid rgb(64, 65, 65);
}

@media (max-width: 600px) {
 .grid-container {
   grid-template-areas:
    "image image rank count"
    "title title title title"
 }
}

.title {
  grid-area: title;
}

.title > h4 {
  font-size: clamp(1rem, 2vw, 1.2rem);
  font-weight: 400;
}
.image {
 grid-area: image;
}

.rank {
 grid-area: rank;
}

.count {
 grid-area: count;
}
</style>
