<template>
  <div class="card h-100">
    <div class="card-body py-1 dx-swatch-dark">
      <div class="row mb-1">
        <div class="p-0 mr-1 align-self-center">
          <dx-util-select-box
            v-model="selectedEmployee"
            :data-source="employees"
            display-expr="text"
            value-expr="value"
            @value-changed="getData"
          />
        </div>
        <div class="mr-lg-1 mb-lg-0 mb-md-0 mb-sm-1">
          <dx-util-select-box
            v-model="selectedDateRange"
            :data-source="dateRangesList"
            :drop-down-options="{width: '180px'}"
            display-expr="text"
            value-expr="value"
            @value-changed="setDateRanges"
          />
        </div>
        <div v-show="isCustomDateRange" class="mr-lg-1 mb-lg-0 mb-md-0 mb-sm-1">
            <dx-util-date-box v-model="beginDate" :max="endDate" type="date" />
        </div>
        <div v-show="isCustomDateRange" class="mr-lg-1 mb-lg-0 mb-md-0 mb-sm-1">
            <dx-util-date-box v-model="endDate" :min="beginDate" type="date" />
        </div>
        <div class="mr-lg-1 mb-lg-0 mb-md-0 mb-sm-1">
          <dx-util-button
            hint="Apply Filters"
            icon="filter"
            type="default"
            text="Update"
            @click="getData"
          />
        </div>
        <div class="p-0 mr-5 align-self-center">
          <dx-util-check-box
            v-model="autoRefresh"
            text="Auto Refresh (Every 5 Minutes)"
          />
        </div>
        <!-- <div class="text-right"> -->
        <div class="ml-lg-auto mr-5 align-self-center font-weight-bold text-warning">
          <h2><strong class="text-warning">{{ title }}</strong></h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-2">
          <dx-chart
            id="chartSize"
            :class="{'full-height': !dashboard}"
            :data-source="dataSource"
            :rotated="selectedEmployee === 0"
            palette="Material"
            >
            <!-- <dx-title :text="title" :font="{ color:titleColor, opacity:1, size:40, weight:600 }" /> -->
            <dx-common-series-settings
              :argument-field="selectedEmployee === 0 ? 'workerName' : 'timeInfo'"
              type="stackedBar"
              :ignore-empty-points="false"
            />
            <dx-common-axis-settings :label="{ displayMode: 'rotate', rotationAngle: 0, font:{ color:titleColor, opacity:1, size:dashboard ? 12 :35, weight:400 }}" />
            <dx-series value-field="receiveQuantity" :name="`Receive (${receiveQuantity})`" />
            <dx-series value-field="problemCreateQuantity" :name="`PRB Create (${problemCreateQuantity})`" />
            <dx-series value-field="problemResolveQuantity" :name="`PRB Resolve (${problemResolveQuantity})`" />
            <dx-series value-field="sortQuantity" :name="`   Sort (${sortQuantity})`" />
            <dx-series value-field="shipQuantity" :name="`   Ship (${shipQuantity})`" />
            <dx-legend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              :marker-size="dashboard ? 12 : 40"
              :font="{ color:titleColor, opacity:1, size: dashboard ? 12 : 40, weight:400 }"
            />
          </dx-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import toString from 'lodash/toString'
import DxChart, {
  // DxTitle,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeries,
} from 'devextreme-vue/chart'
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import userManagementService from '@/http/requests/system/userManagementService'
import basicStatsService from '@/http/requests/stats/basicStatsService'
import { getStandardTimeAbbreviation } from '@/@core/utils/filter'

export default {
  components: {
    'dx-chart': DxChart,
    // 'dx-title': DxTitle,
    'dx-series': DxSeries,
    'dx-common-series-settings': DxCommonSeriesSettings,
    'dx-common-axis-settings': DxCommonAxisSettings,
    'dx-legend': DxLegend,
  },
  props: {
    dashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const today = new Date()
    return {
      dataSource: [],
      autoRefresh: true,
      title: '',
      interval: null,
      beginDate: today,
      endDate: today,
      selectedDateRange: predefinedDateRangesEnum.TODAY.value,
      dateRangesList: getPredefinedDateRangesList(),
      employees: [],
      selectedEmployee: 0,
      receiveQuantity: 0,
      problemCreateQuantity: 0,
      problemResolveQuantity: 0,
      sortQuantity: 0,
      shipQuantity: 0,
    }
  },
  computed: {
    hasPermission() {
      return this.permission ? this.$can('read', this.permission) : true
    },
    titleColor() {
      return this.$color
    },
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    return { setDateRanges, startDate, isCustomDateRange }
  },
  watch: {
    selectedDateRange() {
      this.beginDate = this.startDate || this.beginDate
    },
  },
  created() {
    this.getAllEmployees()
    this.getData()
  },
  mounted() {
    const self = this
    this.interval = setInterval(() => {
      if (self.autoRefresh) {
        this.selectedEmployee = 0
        self.beginDate = new Date()
        self.endDate = new Date()
        self.getData()
      }
    }, 300000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    getData() {
      this.receiveQuantity = 0
      this.problemCreateQuantity = 0
      this.problemResolveQuantity = 0
      this.sortQuantity = 0
      this.shipQuantity = 0
      this.title = `Leader Board for ${this.endDate.getMonth() + 1}/${this.endDate.getDate()}/${this.endDate.getFullYear()}`
      const timeZone = this.endDate.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop()
      const request = {
        warehouseId: 0,
        userId: this.selectedEmployee,
        beginDate: this.beginDate,
        endDate: this.endDate,
      }

      request.timeZone = getStandardTimeAbbreviation(timeZone)

      basicStatsService.getWorkerPerformance(request).then(result => {
        this.dataSource.splice(0, this.dataSource.length)
        this.dataSource = [...result.data]
        if (result.data.length > 0) {
          result.data.forEach(item => {
            this.receiveQuantity += item.receiveQuantity
            this.problemCreateQuantity += item.problemCreateQuantity
            this.problemResolveQuantity += item.problemResolveQuantity
            this.sortQuantity += item.sortQuantity
            this.shipQuantity += item.shipQuantity
          })
        }
      })
    },
    getAllEmployees() {
      this.employees.splice(0, this.employees.length)
      this.employees.push({
        value: 0,
        text: 'Employees (All)',
      })
      userManagementService.fetchAllAccountManagers().then(result => {
        const data = result.data
        data.forEach(element => {
          this.employees.push({
            value: element.id,
            text: element.fullName,
          })
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .full-height {
    min-height: 83vh;
  }
</style>
