/* eslint-disable lines-between-class-members */
class ShopifyAuthorization {
  /** @type {?number} */
  #storeId = 0
  /** @type {?string} */
  #code = ''
  /** @type {?string} */
  #shop = ''

  constructor() {
    this.#storeId = this.storeId
    this.#code = this.code
    this.#shop = this.shop
  }

  shopifyAuthorizationDto() {
    return {
      storeId: this.#storeId,
      code: this.#code,
      shop: this.#shop,
    }
  }

  /** @returns {number} */
  get storeId() {
    return this.#storeId
  }

  /** @param {number} value */
  set storeId(value) {
    this.#storeId = value
  }

  /** @returns {string} */
  get code() {
    return this.#code
  }

  /** @param {string} value */
  set code(value) {
    this.#code = value
  }

  /** @returns {string} */
  get shop() {
    return this.#shop
  }

  /** @param {string} value */
  set shop(value) {
    this.#shop = value
  }
}

export default ShopifyAuthorization
