<template>
  <div class="card rounded-1 p-1">
    <div class="">
      <div class="card-group-title  flex-grow-1 text-nowrap">
        Quick Access
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3 p-half">
        <single-item-tracking class="w-100" />
      </div>
      <div class="col-12 col-md-6 col-lg-3 p-half">
        <upload-shipment-plan class="w-100" />
      </div>
      <div class="col-12 col-md-6 col-lg-3 p-half">
        <shipping-label-card v-if="hasPermission" class="w-100" />
      </div>
      <div class="col-12 col-md-6 col-lg-3 p-half">
        <invitation-card v-if="hasPermission" class="w-100" />
      </div>
    </div>
  </div>
</template>

<script>
import ShippingLabelCard from './ShippingLabelCard.vue'
import InvitationCard from './InvitationCard.vue'
import SingleItemTracking from './SingleItemTracking.vue'
import UploadShipmentPlan from './UploadShipmentPlan.vue'

export default {
  components: {
    'shipping-label-card': ShippingLabelCard,
    'upload-shipment-plan': UploadShipmentPlan,
    'single-item-tracking': SingleItemTracking,
    'invitation-card': InvitationCard,
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
 display: flex;
 flex-wrap: wrap;
 align-items: stretch;
 justify-content: stretch;
 gap: 10px;
 height: auto;
}

</style>
