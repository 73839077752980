/* eslint-disable lines-between-class-members */
class EbayAuthorization {
  /** @type {?number} */
  #storeId = 0
  /** @type {?string} */
  #code = ''
  /** @type {?number} */
  #expiresIn = 0

  constructor() {
    this.#storeId = this.storeId
    this.#code = this.code
    this.#expiresIn = this.expiresIn
  }

  ebayAuthorizationDto() {
    return {
      storeId: this.#storeId,
      code: this.#code,
      expiresIn: this.#expiresIn,
    }
  }

  /** @returns {number} */
  get storeId() {
    return this.#storeId
  }

  /** @param {number} value */
  set storeId(value) {
    this.#storeId = value
  }

  /** @returns {string} */
  get code() {
    return this.#code
  }

  /** @param {string} value */
  set code(value) {
    this.#code = value
  }

  /** @returns {number} */
  get expiresIn() {
    return this.#expiresIn
  }

  /** @param {number} value */
  set expiresIn(value) {
    this.#expiresIn = value
  }
}

export default EbayAuthorization
