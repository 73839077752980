<template>
  <div class="card rounded-1 p-1 flex-grow-1">
    <div class="d-flex justify-content-between align-items-center">
      <div class="card-group-title  flex-grow-1 text-nowrap">
        Task List
      </div>
      <small class="text-info mr-1" role="button" @click="goToTaskManagement">
        Go to Task Management
      </small>
      <div style="font-size:12px !important">
        <div>
          <p-icon name="bi-circle-fill" color="danger" size="12px" /> HIGH
          <p-icon class="ml-1" name="bi-circle-fill" color="warning" size="12px" /> MEDIUM
          <p-icon class="ml-1" name="bi-circle-fill" color="primary" size="12px" /> LOW
        </div>
      </div>
    </div>
    <small class=""></small>
    <div class="card-container py-1 px-0 mx-0">
      <div v-if="taskList && taskList.length > 0">
        <div v-for="(task, i) in taskList" :key="task.id" class="line-item">
          <div class="row align-items-center">
            <div class="col-md-6 pt-half">
              <div class="d-flex">
                <div>
                  <div class="circle" :class="resolvePriority(task.priority)" style="height: 24px;width: 24px;" />
                </div>
                <div class="px-1">
                  {{ i + 1 }}.
                </div>
                <div>
                  <div>
                    {{ task.subject }}
                  </div>
                  <div>
                    <small>
                      {{ task.notes }}
                    </small>
                  </div>
                </div>
                <div class="px-1">
                  <p-icon v-if="task.notes" name="bi-exclamation-circle-fill" size="18px" color="warning" />
                </div>
              </div>
            </div>
            <div class="col-md-6 pt-half">
              <div class="row">
                <div class="col-6 col-md-3 py-half">
                  Due by {{ formatDate(task.startTime) }}
                </div>
                <div class="col-6 col-md-3 text-center">
                  <small class="d-block">
                    {{ task.accountNo }}
                  </small>
                  <small class="d-block">
                    {{ task.storeName }}
                  </small>
                </div>
                <div class="col-12 col-md-6 mx-0 pt-half">
                  <div class="d-flex align-items-center justify-content-end">
                  <div class="text-center badge mr-1" :class="resolveStatus(task.taskStatus)">
                    {{ getStatusEnumValue(task.taskStatus).text }}
                  </div>
                  <div class="mx-half" style="width: 90px;">
                    <dx-util-button v-if="task.taskStatus === 'OPEN'" text="Start" type="default" width="90" @click="updateTask(task.id, 'WORKING')" />
                    <dx-util-button v-if="task.taskStatus === 'WORKING'" text="Complete" width="90" type="warning" @click="updateTask(task.id, 'COMPLETED')" />
                    <dx-util-button v-if="task.taskStatus === 'COMPLETED'" text="DONE" width="90" :disabled="true" />
                    <dx-util-button v-if="(userRoleName === 'WOWNER' || userRoleName === 'SUPERUSER') &&
                      task.taskStatus === 'ON_HOLD'"
                      hint="Make the task ready" width="100"
                      text="Open" type="success"
                      @click="updateTask(task.id, 'OPEN')"
                    />
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-primary" role="alert">
          There is no waiting tasks for you.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TaskPriorityEnum, TaskStatusEnum } from '@/enums'
import { getStatusEnumValue } from '@/enums/taskStatus.enum'
import companyService from '@/http/requests/company/companyService'
import useCurrentUser from '@/libs/app/current-user'
import { formatDate } from '@core/utils/filter'

const today = new Date()
const fiveDaysBefore = new Date(today)
fiveDaysBefore.setDate(today.getDate() - 5)
export default {
  data() {
    return {
      taskList: [],
      getStatusEnumValue,
      formatDate,
    }
  },
  setup() {
    const { userId, userRoleName } = useCurrentUser()
    return {
      userId, userRoleName,
    }
  },
  mounted() {
    this.getUserTasks()
  },
  methods: {
    async getUserTasks() {
      const response = await companyService.getTasksForDashboard()
      this.taskList = response.filter(el => el.taskStatus !== TaskStatusEnum.COMPLETED.value)
    },
    resolvePriority(priority) {
      if (priority === TaskPriorityEnum.HIGH.value) return 'bg-danger'
      if (priority === TaskPriorityEnum.MEDIUM.value) return 'bg-warning'
      if (priority === TaskPriorityEnum.LOW.value) return 'bg-success'
      if (priority === TaskPriorityEnum.HIGH.value) return 'bg-danger'
      return 'bg-primary'
    },
    resolveStatus(status) {
      if (status === TaskStatusEnum.COMPLETED.value) return 'badge-light-success'
      if (status === TaskStatusEnum.WORKING.value) return 'badge-light-warning'
      if (status === TaskStatusEnum.ON_HOLD.value) return 'badge-light-danger'
      if (status === TaskStatusEnum.OPEN.value) return 'badge-light-primary'
      return 'badge-primary'
    },
    async updateTask(taskId, status) {
      await companyService.updateTask({ id: taskId, taskStatus: status })
      this.getUserTasks()
    },
    goToTaskManagement() {
      this.$router.push({ name: 'route-task-management' })
    },
  },
}
</script>

<style lang="scss" scoped>
.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

div.dark-layout .line-item  {
  background-color: rgba(51, 55, 80, 0.8);
  color: rgb(230, 233, 237);
  border-color: rgb(80, 81, 92);
}

div.light-layout .line-item {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
  border-color: rgb(109, 128, 150);
}

div.bordered-layout .line-item  {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
  border-color: rgb(109, 128, 150);
}

.line-item  {
  padding: 10px;
  margin: 6px;
  padding: 12px;
  border-radius: 5px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1600px;
}
</style>
