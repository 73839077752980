<template>
  <div class="container-fluid pb-1">
    <div class="row">
      <div v-if="!isIndividualTenant" class="col-12 px-half">
        <div class="p-1 rounded text-right card py-0 mb-half">
          <div class="d-flex justify-content-between">
            <working-days />
            <div class="flex-grow-1"></div>
            <div class="d-flex flex-column">
              <span>
                My Shipping Address:
              </span>
              <strong class="text-danger">
                {{ address }}
                <copy-button v-if="address" :copy-value="address" />
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isWarehouseOwner && showDisputesCard" class="col-12 px-half my-half my-lg-0">
        <disputes-card @emit-disputes-count="updateDisputesCount" />
      </div>
      <div class="col-12 px-half my-half my-lg-0">
        <quick-actions class="h-100" />
      </div>
      <div v-if="hasPermission" class="col-12 px-half my-half my-lg-0">
        <task-list class="h-100" />
      </div>
      <div v-if="showWalmartStoreMessageCard" class="col-12 mt-1">
        <div class="card-deck">
          <div class="card item-box item-box-light">
            <div class="card-body py-1">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <i class="bi bi-exclamation-circle-fill display-3 text-warning"></i>
                <div class="d-flex flex-column justify-content-start ml-1 item-info">
                  <span>
                    To continue Walmart authorization process, please add your Walmart store at
                  </span>
                  <div class="my-half">
                    Go to
                    <span class="font-weight-bold">Account</span>
                    <i class="bi bi-chevron-double-right text-danger"></i>
                    <span class="font-weight-bold"> My Company</span>
                    <i class="bi bi-chevron-double-right text-danger"></i>
                    <router-link :to="{ name: 'route-account-my-company', params: { companyId: 0 } }">
                      Stores Tab
                    </router-link>
                    add a new store.
                  </div>
                  <div></div>
                  <div>
                    <dx-util-button styling-mode="contained" type="success" icon="bi bi-arrow-right-short" text="My Account" width="150px" @click="onClickMyAccount" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasNewVersion" class="col-sm-12">
        <div class="card">
          <div class="d-flex flex-column justify-content-start py-1 px-1">
            <div class="card-deck">
              <div class="card item-box item-box-light">
                <div class="card-body py-1">
                  <div class="d-flex flex-row justify-content-start align-items-center">
                    <img :src="require('@/assets/images/icons/printer.png')" class="img-fluid sm-img">
                    <div class="d-flex flex-column justify-content-start ml-1 item-info text-danger">
                      <h4 class="text-danger font-weight-bolder">
                        New Printer Agent Version
                      </h4>
                      <span class="text-info font-weight-bolder">
                        A new PSH Printer Agent version has beent released. Please update your printer agen version.
                      </span>
                    </div>
                    <div class="ml-auto">
                      <dx-util-button styling-mode="outlined" type="danger" icon="close" hint="Don't Show Again" @click="onClickCloseVersionInfo($event)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="duePlanExists || dueTrialPlanExists" class="col-sm-12 px-half">
        <div v-if="duePlanExists" class="card mx-0">
          <div class="d-flex flex-column justify-content-start py-1 px-1">
            <div class="d-flex flex-row justify-content-start mb-1">
              <div class="flex-grow-1 align-self-center">
                <h6 class="card-group-title">
                  Failed Membership Payment
                </h6>
              </div>
            </div>
            <div class="card-deck">
              <div class="card item-box item-box-light">
                <div class="card-body py-1">
                  <div class="d-flex flex-row justify-content-start align-items-center">
                    <i class="bi bi-exclamation-circle-fill display-3 text-warning"></i>
                    <div v-for="(plan, index) in duePlans" :key="index" class="d-flex flex-column justify-content-start ml-1 item-info">
                      <span class="display-h-4 text-black badge badge-light-danger">
                        Due to a <strong>failed membership payment,</strong> you will not be able to access the features of {{ plan.planName }}. Your due amount is ${{ plan.dueAmount }}
                      </span>
                      <div class="my-half">
                        Please update your payment method to continue using PrepShipHub.
                        <br>
                        Go to <span class="font-weight-bold">Account</span>
                        <i class="bi bi-chevron-double-right text-danger"></i>
                        <span class="font-weight-bold"> My Company</span>
                        <i class="bi bi-chevron-double-right text-danger"></i>
                        <router-link :to="{ name: 'route-account-my-company', params: { companyId: 0 } }">
                          Payment Methods Tab
                        </router-link>
                        to update or add a new credit card.
                      </div>
                      <div></div>
                      <div>
                        <dx-util-button styling-mode="contained" type="success" icon="bi bi-arrow-right-short" text="My Account" width="150px" @click="onClickMyAccount" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="dueTrialPlanExists" class="card mx-0">
          <div class="d-flex flex-column justify-content-start py-1 px-1">
            <div class="d-flex flex-row justify-content-start mb-1">
              <div class="flex-grow-1 align-self-center">
                <h6 class="card-group-title">
                  Trial Period Ended
                </h6>
              </div>
            </div>
            <div class="card-deck">
              <div class="card item-box item-box-light">
                <div class="card-body py-1">
                  <div class="d-flex flex-row justify-content-start align-items-center">
                    <i class="bi bi-exclamation-circle-fill display-3 text-warning"></i>
                    <div v-for="(plan, index) in dueTrialPlans" :key="index" class="d-flex flex-column justify-content-start ml-1 item-info">
                      <span class="display-h-4 text-danger mb-half text-left">
                        Your trial period has <strong>ended</strong> for <span> {{ plan.planName }}</span>
                      </span>
                      <span> To continue using PrepShipHub, the due amount is ${{ plan.dueAmount }}. </span>
                      <div class="my-half">
                        Please add or update your payment methods and subscribe to the plan to continue using PrepShipHub.
                        <br>
                        Go to
                        <span class="font-weight-bold">Account</span>
                        <i class="bi bi-chevron-double-right text-danger"></i>
                        <span class="font-weight-bold"> My Company</span>
                        <i class="bi bi-chevron-double-right text-danger"></i>
                        <router-link :to="{ name: 'route-account-my-company', params: { companyId: 0 }, query: { tab: 5 } }">
                          Payment Methods Tab
                        </router-link>
                        to update or add a new credit card. Once you add a new payment method, pay any due amount at Membership tab in My Company page.
                      </div>
                      <div></div>
                      <div>
                        <dx-util-button styling-mode="contained" type="success" icon="bi bi-arrow-right-short" text="My Account" width="150px" @click="onClickMyAccount" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isShownAuth" class="col-sm-12 px-half pt-half">
        <div class="card my-0">
          <div class="d-flex flex-column justify-content-start py-1 px-1">
            <div class="d-flex flex-row justify-content-start mb-1">
              <div class="flex-grow-1 align-self-center">
                <h6 class="card-group-title">
                  Authorize PrepShipHub for store access
                </h6>
              </div>
            </div>
            <div class="card-deck d-flex flex-wrap">
              <div v-for="store in stores" :key="store.id" class="card item-box item-box-light m-half" style="min-width: 360px !important;">
                <div v-if="isSupportedPlatform(store)">
                  <div class="card-body py-1">
                    <div v-if="store.commercePlatform === cartId.AMAZONSP.value" class="d-flex flex-column justify-content-start item-info">
                      <div class="d-flex flex-row justify-content-between mb-1">
                        <div class="w-100 text-center">
                          <img :src="require(`@/assets/images/svg/ecommerce/${store.commercePlatform.toLowerCase()}.svg`)" class="align-self-center" height="36px">
                        </div>
                        <div>
                          <dx-util-button styling-mode="contained" type="danger" icon="close" hint="Don't Show Again" @click="onClickAskAuthorize($event, store.id)" />
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="flex-grow-1 px-2">
                          <h3 class="display-h-3 text-danger">
                            ATTENTION: Please enable 2D Barcode Program on Amazon for
                            <span class="badge badge-danger">{{ store.name }}</span> store.
                          </h3>
                          <span class="text-info font-weight-bold">To enable the 2D barcode option, follow these steps: </span>
                          <ol class="text-info pl-1">
                            <li>Go to Fulfillment by Amazon under settings.</li>
                            <li>Locate Inbound Settings and click Edit.</li>
                            <li>Select Enable next to 2D Barcodes for Box Content Information.</li>
                            <li>Finally click on Update button to persist the settings.</li>
                          </ol>
                          <dx-util-button
                            styling-mode="contained"
                            type="success"
                            icon="bi bi-shield-fill-plus"
                            text="Authorize Now"
                            width="150px"
                            @click="onClickPreAuthorize($event, store.id, store.commercePlatform)"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="store.commercePlatform === cartId.SHOPIFY.value" class="d-flex flex-column justify-content-start item-info">
                      <div class="d-flex flex-row justify-content-between mb-1">
                        <div class="w-100 text-center">
                          <img :src="require(`@/assets/images/svg/ecommerce/${store.commercePlatform.toLowerCase()}.svg`)" class="align-self-center" height="36px">
                        </div>
                        <div>
                          <dx-util-button styling-mode="contained" type="danger" icon="close" hint="Don't Show Again" @click="onClickAskAuthorize($event, store.id)" />
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="flex-grow-1 px-2">
                          <h3 class="display-h-3 text-danger">
                            ATTENTION: Please authorize your
                            <span class="badge badge-danger">{{ store.name }} </span> store on {{ cartId.SHOPIFY.value }}.
                          </h3>
                          <div class="text-info font-weight-bold mb-2">
                            Please authorize your stores to enable us to fulfill your customer orders and synchronize seamlessly with {{ cartId.SHOPIFY.value }}.
                            <br>
                            This authorization ensures a secure and reliable transaction process, protecting both you and your customers while maintaining the integrity of your data.
                          </div>
                          <dx-util-button
                            styling-mode="contained"
                            type="success"
                            icon="bi bi-shield-fill-plus"
                            text="Authorize Now"
                            width="150px"
                            @click="onClickPreAuthorize($event, store.id, store.commercePlatform)"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="store.commercePlatform === cartId.EBAY.value" class="d-flex flex-column justify-content-start item-info">
                      <div class="d-flex flex-row justify-content-between mb-1">
                        <div class="w-100 text-center">
                          <img :src="require(`@/assets/images/svg/ecommerce/${store.commercePlatform.toLowerCase()}.svg`)" class="align-self-center" height="36px">
                        </div>
                        <div>
                          <dx-util-button styling-mode="contained" type="danger" icon="close" hint="Don't Show Again" @click="onClickAskAuthorize($event, store.id)" />
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="flex-grow-1 px-2">
                          <h3 class="display-h-3 text-danger">
                            ATTENTION: Please authorize your
                            <span class="badge badge-danger">{{ store.name }} </span> store on {{ cartId.EBAY.value }}.
                          </h3>
                          <div class="text-info font-weight-bold mb-2">
                            Please authorize your stores to enable us to fulfill your customer orders and synchronize seamlessly with {{ cartId.EBAY.value }}.
                            <br>
                            This authorization ensures a secure and reliable transaction process, protecting both you and your customers while maintaining the integrity of your data.
                          </div>
                          <dx-util-button
                            styling-mode="contained"
                            type="success"
                            icon="bi bi-shield-fill-plus"
                            text="Authorize Now"
                            width="150px"
                            @click="onClickPreAuthorize($event, store.id, store.commercePlatform)"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="store.commercePlatform === cartId.WALMART.value" class="d-flex flex-column justify-content-start item-info">
                      <div class="d-flex flex-row justify-content-between mb-1">
                        <div class="w-100 text-center">
                          <img :src="require(`@/assets/images/svg/ecommerce/${store.commercePlatform.toLowerCase()}.svg`)" class="align-self-center" height="36px">
                        </div>
                        <div>
                          <dx-util-button styling-mode="contained" type="danger" icon="close" hint="Don't Show Again" @click="onClickAskAuthorize($event, store.id)" />
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="flex-grow-1 px-2">
                          <h3 class="display-h-4 text-danger">
                            ATTENTION: Please authorize your
                            <span class="badge badge-danger">{{ store.name }} </span> store on {{ cartId.WALMART.value }}.
                          </h3>
                          <div class="text-info font-weight-bold mb-2">
                            Please authorize your stores to enable us to fulfill your customer orders and synchronize seamlessly with {{ cartId.WALMART.value }}.
                            <br>
                            This authorization ensures a secure and reliable transaction process, protecting both you and your customers while maintaining the integrity of your data.
                          </div>
                          <dx-util-button
                            styling-mode="contained"
                            type="success"
                            icon="bi bi-shield-fill-plus"
                            text="Authorize Now"
                            width="150px"
                            @click="onClickPreAuthorize($event, store.id, store.commercePlatform)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="userData.storeCount === 0 && (userData.companyType !== 'warehouse' || userData.tenantType === 'INDIVIDUAL')" class="col-sm-12">
        <div class="card bg-light-danger border-danger p-2 my-0">
          <h4 class="px-half pb-1">
            Missing Store
          </h4>
          <div class="card-body px-half py-0">
            You have not created a store. Please create a store in order to start to upload items. You can add your stores at
            <router-link :to="{name: 'route-account-my-company', query: { tab: 4 }}">
              <strong>
                Account > My Account page under the Stores tab
              </strong>
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="false && userData.storeCount === 0 && (userData.companyType !== 'warehouse' || userData.tenantType === 'INDIVIDUAL')" class="col-sm-12">
        <div class="card bg-light-danger border-danger p-2 my-0">
          <h4 class="px-half pb-1">
            Not Enrolled Any Plans Yet
          </h4>
          <div class="card-body px-half py-0">
            You have not enrolled any plans yet. Please enroll at least a plan at Account > Plans > Plan Enrollment page.
          </div>
        </div>
      </div>
      <div v-if="!hasCreditCards && (userData.companyType !== 'warehouse' || (userData.tenantType === 'INDIVIDUAL' && remainingTrialDays[0] < 6))" class="col-sm-12 pt-1">
        <div class="card bg-light-danger border-danger p-2 my-0">
          <h4 class="px-half pb-1">
            Missing Payment Method
          </h4>
          <div class="card-body px-half py-0">
            You have not added any Payment Methods yet. Please add at last one payment method at
            <router-link :to="{name: 'route-account-my-company', query: { tab: 7 }}">
              <strong>
                Account > My Company page under the Payment Methods tab.
              </strong>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 my-half mb-2">
        <div class="row align-items-stretch">
          <div class="col-12 col-md-6 px-half my-half my-lg-0">
            <item-counts class="h-100" />
          </div>
          <div class="col-12 col-md-6 px-half my-half my-lg-0">
            <urgency-stats class="h-100" />
          </div>
          <div class="col-12 col-md-6 px-half my-half my-lg-0 ">
            <top-n-products v-if="!hasPermission" class="h-100" />
            <div v-if="hasPermission" style="height: 500px;">
              <worker-performance dashboard />
            </div>
          </div>
          <div class="col-12 col-md-6 px-half my-half my-lg-0">
            <the-side-by-side-bar-chart-statistic
              :id="getUid"
              endpoint="dashboard-stats/combined-data"
              class=""
              :request-data="{ statsType: 'uploaded_received_shipped_monthly' }"
              title="Monthly Item Snapshot"
            />
          </div>
        </div>
      </div>
    </div>
    <tokenize-credit-card v-if="reCreateTokenizeCreditCardComponent" @on-emit-close-tokenize-credit-card="onEmitCloseTokenizeCreditCard" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable quote-props */

import { custom } from 'devextreme/ui/dialog'
import axios from 'axios'
import useTenantSettigns from '@/libs/app/tenant'
import { getUserData } from '@/auth/utils'
import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'
import { buildUrl, generateRandomString } from '@/libs/utils'
import { Notify } from '@robustshell/utils/index'
import commonEntityService from '@/http/requests/common/commonEntityService'
import amzApiService from '@/http/requests/external/amazon.auth'
import wfsService from '@/http/requests/wfs'
import staxService from '@/http/requests/external/stax'
import AmzAuthorization from '@/http/models/external/AmzAuthorization'
import WalmartAuthorization from '@/http/models/external/WalmartAuthorization'
import isEmpty from 'lodash/isEmpty'
import MerchantPlatformsEnum from '@/enums/merchant-platforms.enum'
import EbayAuthorization from '@/http/models/external/EbayAuthorization'
import ebayService from '@/http/requests/external/ebay'
import fbmService from '@/http/requests/fbm/fbmService'
import ShopifyAuthorization from '@/http/models/external/ShopifyAuthorization'
import shopifyService from '@/http/requests/external/shopify'
import usePrinterHelper from '@/libs/printer/printer-helper'
import TokenizeCreditCard from '@/views/apps/company/credit-card//TokenizeCreditCard.vue'
import useCurrentUser from '@/libs/app/current-user'
import DisputesCard from '@/views/apps/account/merchant-connect/components/DisputesCard.vue'
import TopNProducts from './components/TopNProducts.vue'
import UrgencyStats from './components/UrgencyStats.vue'
import useStatisticsCardState from './components/useStatisticsState'
import WorkingDays from './components/WorkingDays.vue'
import QuickActions from './components/QuickActions.vue'
import WorkerPerformance from '../leader-board/WorkerPerformance.vue'
import TaskList from './components/TaskList.vue'
import { isProduction } from '@/libs/env.js'

const threeMonthAgo = new Date()
const oneDayAfter = new Date()
threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3)
oneDayAfter.setDate(oneDayAfter.getDate() + 1)

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const queryParams = { ...to.query }
      if (!isEmpty(queryParams)) {
        if (queryParams.auth && queryParams.auth === 'ebay') {
          const payload = {
            auth: queryParams.auth,
            code: queryParams.code,
            expires_in: queryParams.expires_in,
          }
          vm.onUpdateCustomerEbayPermission(payload)
        } else if (queryParams.auth && queryParams.auth === 'shopify') {
          const payload = {
            auth: queryParams.auth,
            code: queryParams.code,
            shop: queryParams.shop,
          }
          vm.onUpdateCustomerShopifyPermission(payload)
        } else if (queryParams.spapi_oauth_code) {
          const payload = {
            spapi_oauth_code: queryParams.spapi_oauth_code,
            state: queryParams.state,
            selling_partner_id: queryParams.selling_partner_id,
          }
          vm.onUpdateCustomerAwsPermission(payload)
        } else if (queryParams.auth && queryParams.auth === 'walmart') {
          if (!queryParams.clientId) {
            vm.onInitWalmartAuth(queryParams)
          } else {
            vm.onCreateWalmartPermission(queryParams)
          }
        }
      }
    })
  },
  components: {
    DisputesCard,
    'the-side-by-side-bar-chart-statistic': () => import('./components/TheSideBySideBarChartStatistic.vue'),
    'item-counts': () => import('./components/ItemCounts.vue'),
    TokenizeCreditCard,
    'top-n-products': TopNProducts,
    'urgency-stats': UrgencyStats,
    'working-days': WorkingDays,
    'quick-actions': QuickActions,
    'worker-performance': WorkerPerformance,
    'task-list': TaskList,
  },
  setup() {
    const { address } = useTenantSettigns()
    const { hasNewVersion } = usePrinterHelper()
    const {
      userCompanyId,
      duePlans,
      duePlanExists,
      dueTrialPlans,
      dueTrialPlanExists,
      remainingTrialDays,
      userRoleName,
    } = useCurrentUser()
    const { itemCounts } = useStatisticsCardState()

    return {
      address,
      hasNewVersion,
      userCompanyId,
      duePlans,
      duePlanExists,
      dueTrialPlanExists,
      dueTrialPlans,
      itemCounts,
      remainingTrialDays,
      userRoleName,
    }
  },
  data() {
    return {
      cartId: MerchantPlatformsEnum,
      beginDate: threeMonthAgo,
      accountOrSuite: '',
      endDate: oneDayAfter,
      stores: [],
      isWaitingEnable: false,
      isShownAuth: false,
      hasCreditCards: true,
      reCreateTokenizeCreditCardComponent: false,
      companyData: {},
      isIndividualTenant: false,
      showWalmartStoreMessageCard: false,
      showDisputesCard: false,
      disputesCount: 0,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    userData() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData
    },
    accountNo() {
      return parseInt(this.userData.accountNo, 10)
    },
    showPendingCompanyCount() {
      return this.pendingCompanyCount > 0 && this.$can('read', 'resource_customer_account_no')
    },
    isWarehouseOwner() {
      return this.userRoleName === 'WOWNER' || this.userRoleName === 'SUPERUSER'
    },
  },
  created() {
    // eslint-disable-next-line no-undef
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
    const { storeId, platform } = this.$route.query
    if (storeId && platform) {
      this.onClickPreAuthorize(null, storeId, platform)
    }
  },
  async mounted() {
    this.getStoreList()
    await this.onShownPayCreditCardPopup()
    if (isProduction) {
      await this.updateDisputes()
    }
  },
  methods: {
    isSupportedPlatform(store) {
      return (
        store.commercePlatform === MerchantPlatformsEnum.EBAY.value
        || store.commercePlatform === MerchantPlatformsEnum.SHOPIFY.value
        || store.commercePlatform === MerchantPlatformsEnum.AMAZONSP.value
        || store.commercePlatform === MerchantPlatformsEnum.WALMART.value
      )
    },
    getStoreList(askAuth = false) {
      this.stores = []
      const self = this
      commonEntityService.fetchStoresByParentCompanyAccountNo(this.accountNo).then(result => {
        const data = result.data
        data.forEach(store => {
          if (((store.askAuth && store.isBeta) || askAuth)
          && (store.commercePlatform === MerchantPlatformsEnum.AMAZONSP.value
          || store.commercePlatform === MerchantPlatformsEnum.EBAY.value
          || store.commercePlatform === MerchantPlatformsEnum.SHOPIFY.value
          || store.commercePlatform === MerchantPlatformsEnum.WALMART.value)
          ) {
            if (store.isBeta) {
              self.isShownAuth = true
            }
            self.stores.push({
              id: store.id,
              name: store.name,
              isBeta: store.isBeta,
              commercePlatform: store.commercePlatform,
              ecommerceSite: store.ecommerceSite,
            })
          }
        })
      })
    },
    async onShownPayCreditCardPopup() {
      const { companyId } = getUserData()
      const response = await staxService.getAllCreditCardsByCompanyId(companyId)
      if (!response || response.length === 0) {
        this.hasCreditCards = false
      }
    },
    getUid() {
      return uuidv4()
    },
    searchByFilter() {
      this.$emit('update::search-filter', this.accountOrSuite, this.beginDate, this.endDate)
    },
    clearFilter() {
      this.accountOrSuite = ''
      this.beginDate = threeMonthAgo
      this.endDate = oneDayAfter
      this.$emit('update::search-filter', null, null, null)
    },
    onClickMyAccount() {
      this.$router.push({ name: 'route-account-my-company', params: { companyId: 0 }, query: { tab: 5 } })
    },
    // Authorization process
    onClickAskAuthorize(e, storeId) {
      const askAuthorizeDialog = custom({
        title: 'Store Authorization',
        messageHtml: `<b>Please know that you need to authorize PrepShipHub for store connection in order for your service provider to handle your shipments on your behalf.</b><br/>
        You can authorize later from <span class="text-danger"><b>Account > My Company menu under Stores tab</b></span>.`,
        buttons: [
          {
            text: 'Confirm',
            type: 'default',
            stylingMode: 'contained',
            elementAttr: { class: 'float-left' },
            value: true,
            onClick: arg => ({ result: arg.component.option('value') }),
          },
          {
            text: 'Cancel',
            type: 'danger',
            stylingMode: 'contained',
            value: false,
            onClick: arg => ({ result: arg.component.option('value') }),
          },
        ],
      })
      askAuthorizeDialog.show().then(async dialogResult => {
        if (dialogResult.result) {
          commonEntityService.unsetAskAuth(storeId).then(response => {
            this.getStoreList()
          })
        }
      })
    },
    onClickPreAuthorize(e, storeId, platform) {
      let message = ''
      if (platform === MerchantPlatformsEnum.AMAZONSP.value) {
        message = 'Authorize Permissions for PrepShipHub Application on the Amazon Appstore'
      }
      if (platform === MerchantPlatformsEnum.EBAY.value) {
        message = 'Authorize Permissions for PrepShipHub Application on the EBay Appstore'
      }
      if (platform === MerchantPlatformsEnum.SHOPIFY.value) {
        message = 'Authorize Permissions for PrepShipHub Application on the Shopify Appstore'
      }
      if (platform === MerchantPlatformsEnum.WALMART.value) {
        message = 'Authorize Permissions for PrepShipHub Application on the Walmart'
      }
      const authorizeDialog = custom({
        title: 'PrepShipHub Application',
        messageHtml: message,
        buttons: [
          {
            text: 'Authorize',
            type: 'default',
            stylingMode: 'contained',
            elementAttr: { class: 'float-left' },
            value: true,
            onClick: arg => ({ result: arg.component.option('value') }),
          },
          {
            text: 'Cancel',
            type: 'danger',
            stylingMode: 'contained',
            value: false,
            onClick: arg => ({ result: arg.component.option('value') }),
          },
        ],
      })
      authorizeDialog.show().then(dialogResult => {
        if (dialogResult.result) {
          const preAuth = JSON.stringify({ storeId: storeId, accountNo: this.accountNo })
          localStorage.setItem('preAuth', preAuth)
          if (platform === MerchantPlatformsEnum.AMAZONSP.value) {
            this.awsRedirect()
            return true
          }
          if (platform === MerchantPlatformsEnum.EBAY.value) {
            this.ebayRedirect()
            return true
          }
          if (platform === MerchantPlatformsEnum.SHOPIFY.value) {
            this.shopifyRedirect()
            return true
          }
          if (platform === MerchantPlatformsEnum.WALMART.value) {
            this.walmartRedirect(storeId)
            return true
          }
        }
        return false
      })
    },
    // #region Amazon Auth
    awsRedirect() {
      const uId = uuidv4()
      window.open(`https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.5313bd03-ab9f-495f-9bec-e75e86c7764a&version=beta&state=${uId}`, '_self')
    },
    async onUpdateCustomerAwsPermission(payload) {
      this.isWaitingEnable = true
      const preAuth = JSON.parse(localStorage.getItem('preAuth'))

      const dto = new AmzAuthorization()
      dto.storeId = preAuth.storeId
      dto.partnerId = payload.selling_partner_id
      dto.apiAuthCode = payload.spapi_oauth_code

      amzApiService
        .create(dto.amzAuthorizationDto())
        .then(result => {
          const data = { ...result.data.body }
          if (data.id) {
            dto.id = data.id
            dto.apiAuthCode = data.apiAuthCode
            return data
          }
          throw new Error('Your data has not been saved. Please try again later.')
        })
        .then(async () => {
          const data = await amzApiService.getClientSecret()
          return data
        })
        .then(async data => {
          const url = 'https://api.amazon.com/auth/o2/token'
          const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          }
          const authParams = {
            grant_type: 'authorization_code',
            client_id: 'amzn1.application-oa2-client.c05581e128fd4c0bb51e9440adb870b8',
            client_secret: data,
            code: dto.apiAuthCode,
          }
          try {
            const tokenResponse = await axios.post(url, qs.stringify(authParams), { headers })
            if (tokenResponse.status === 200) {
              return tokenResponse.data
            }
            throw new Error(`Failed getting access token, error: ${tokenResponse.statusText}`)
          } catch (err) {
            throw new Error(`Failed getting access token, error: ${err.message}`)
          }
        })
        .then(async token => {
          dto.accessToken = token.access_token
          dto.accessTokenExpiresAt = token.expires_in
          dto.tokenType = token.token_type
          dto.refreshToken = token.refresh_token
          const amzAuthResponse = await amzApiService.update(dto.amzAuthorizationDto())
        })
        .then(async () => {
          await amzApiService.fetchSellerInventoryData(dto.storeId)
        })
        .then(async () => {
          const storeResult = await commonEntityService.fetchECommerceStoreById(preAuth.storeId)
          const store = { ...storeResult.data.body }
          store.askAuth = false
          store.isAuthorized = true
          commonEntityService.updateStore(store).then(response => {
            localStorage.removeItem('preAuth')
            this.getStoreList()
          })
        })
        .catch(err => {
          Notify.danger(err.message)
        })
        .finally(() => {
          this.$router.replace({ name: 'dashboard-statistics' })
        })
    },
    // #endregion
    // #region EBay Auth
    async ebayRedirect() {
      const redirectUri = await ebayService.generateUserAuthorizationUrl()
      window.open(redirectUri, '_self')
      return false
    },
    async shopifyRedirect() {
      const preAuth = JSON.parse(localStorage.getItem('preAuth'))
      const redirectUri = await shopifyService.generateUserAuthorizationUrl(preAuth.storeId)
      window.open(redirectUri, '_self')
      return false
    },
    async onUpdateCustomerEbayPermission(payload) {
      this.isWaitingEnable = true
      const preAuth = JSON.parse(localStorage.getItem('preAuth'))
      const dto = new EbayAuthorization()
      dto.storeId = preAuth.storeId
      dto.code = payload.code
      dto.expiresIn = payload.expires_in
      try {
        const tokenResponse = await ebayService.connectStore(dto.ebayAuthorizationDto())
        if (tokenResponse.status === 200) {
          const storeResult = await commonEntityService.fetchECommerceStoreById(dto.storeId)
          const store = { ...storeResult.data.body }
          if (store.isAuthorized) {
            localStorage.removeItem('preAuth')
          }
          await fbmService.pullStoreOrders(dto.storeId).then(() => {
            this.getStoreList()
          })
        }
        throw new Error(`Failed getting access token, error: ${tokenResponse.statusText}`)
      } catch (err) {
        throw new Error(`Failed getting access token, error: ${err.message}`)
      } finally {
        this.$router.replace({ name: 'dashboard-statistics' })
        window.location.reload()
      }
    },
    async onUpdateCustomerShopifyPermission(payload) {
      this.isWaitingEnable = true
      const preAuth = JSON.parse(localStorage.getItem('preAuth'))
      const dto = new ShopifyAuthorization()
      dto.storeId = preAuth.storeId
      dto.code = payload.code
      dto.shop = payload.shop
      try {
        const tokenResponse = await shopifyService.createShopifyCart(dto.shopifyAuthorizationDto())

        if (tokenResponse.status === 200) {
          const storeResult = await commonEntityService.fetchECommerceStoreById(dto.storeId)
          const store = { ...storeResult.data.body }
          if (store.isAuthorized) {
            localStorage.removeItem('preAuth')
          }
          this.getStoreList()
        }
        throw new Error(`Failed getting access token, error: ${tokenResponse.statusText}`)
      } catch (err) {
        throw new Error(`Failed getting access token, error: ${err.message}`)
      } finally {
        this.$router.replace({ name: 'dashboard-statistics' })
        window.location.reload()
      }
    },
    onClickCloseVersionInfo(e) {
      this.hasNewVersion = false
    },
    // #endregion
    // #region Walmart Auth
    onInitWalmartAuth() {
      this.getStoreList(true)
      if (this.stores.length > 0) {
        const store = this.stores.find(s => s.commercePlatform === MerchantPlatformsEnum.WALMART.value)
        if (!store) {
          this.showWalmartStoreMessageCard = true
        }
      } else {
        this.showWalmartStoreMessageCard = true
      }
    },
    async onCreateWalmartPermission(payload) {
      try {
        const dto = new WalmartAuthorization()
        dto.storeId = parseInt(payload.state, 10)
        dto.partnerId = payload.sellerId
        dto.apiAuthCode = payload.code
        const createAuth = await wfsService.createAuth(dto.walmartAuthorizationDto())
        if (createAuth.id) {
          dto.id = createAuth.id
          dto.apiAuthCode = createAuth.apiAuthCode
        } else {
          throw new Error('Your data has not been saved. Please try again later.')
        }
        // Walmart Token Request
        const wmtToken = await wfsService.getAppAuthorization()
        const uId = uuidv4()
        const url = 'https://marketplace.walmartapis.com/v3/token'
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'WM_PARTNER.ID': payload.sellerId,
          'Authorization': wmtToken,
          'WM_CONSUMER.CHANNEL.TYPE': '1d5f2498-a71c-49f3-ac46-8babf3492c95',
          'WM_QOS.CORRELATION_ID': uId,
          'WM_SVC.NAME': 'PrepShipHub',
        }
        const authParams = {
          grant_type: 'authorization_code',
          code: dto.apiAuthCode,
          redirect_uri: 'https://app.prepshiphub.com?auth=walmart',
        }
        try {
          const tokenResponse = await axios.post(url, qs.stringify(authParams, { encode: false }), { headers })
          if (tokenResponse.status === 200) {
            dto.accessToken = tokenResponse.data.access_token
            dto.refreshToken = tokenResponse.data.refresh_token
            dto.tokenType = tokenResponse.data.token_type
            dto.expiresIn = tokenResponse.data.expires_in

            const updateAuth = await wfsService.updateAuth(dto)
          } else {
            throw new Error(`Failed getting access token, error: ${tokenResponse.statusText}`)
          }
        } catch (err) {
          throw new Error(`Failed getting access token, error: ${err.message}`)
        }
      } catch (err) {
        throw new Error(`Failed getting access token, error: ${err.message}`)
      } finally {
        this.$router.push({ name: 'dashboard-statistics' })
      }
    },
    walmartRedirect(storeId) {
      const baseUrl = 'https://login.account.wal-mart.com/authorize'
      const params = {
        responseType: 'code',
        clientId: '2a481a3f-1ab9-4c11-85ee-aa6fecc9ad53',
        redirectUri: 'https://app.prepshiphub.com?auth=walmart',
        clientType: 'seller',
        nonce: generateRandomString(10),
        state: storeId,
      }
      const url = buildUrl(baseUrl, params)
      window.open(url, '_self')
    },
    updateDisputesCount(count) {
      this.disputesCount = count
      if (count < 1) {
        this.showDisputesCard = false
      }
    },
    async updateDisputes() {
      const response = await staxService.getMerchantInfo(this.userCompanyId)
      if (response !== null) {
        this.showDisputesCard = true
      }
    },
    // #endregion
  },
}
</script>
