<template>
  <div class="d-inline d-flex mx-0 px-0 h-100 align-items-center">
    <div class="statistics-card flex-grow-1" :class="{'pulsing-border': false}">
      <div class="d-flex justify-content-between">
        <div class="pt-half flex-grow-1">
          <div class="">
            <strong style="font-size: 18px; font-weight: 900;" class="d-block">
             Pending Shipment Plans
            </strong>
          </div>
          <small class="d-block">
            The total counts of shipment plans that are in an open or boxing status.
            <p>
              See <strong class="text-primary" role="button" @click="$router.push({name: 'fba-pending-batches', params: { channel: 'fba'}})">FBA Details</strong>
              See <strong class="text-primary" role="button" @click="$router.push({name: 'wfs-pending-batches', params: { channel: 'wfs'}})">WFS Details</strong>
            </p>
          </small>
        </div>
        <div class="d-flex justify-content-end pt-half">
          <div v-for="(count, key) in openBatchCounts" :key="key" class="mx-1">
            <div class="d-flex flex-column justify-content-end">
              <div class="d-flex text-center py-0 align-items-center">
                <small class="py-0 mx-auto text-nowrap">
                  {{ key }}
                </small>
              </div>
              <div class="text-center">
                <strong class="text-black" style="font-size: 22px;">
                  {{ formatNumber(count) }}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStatisticsCardState from './useStatisticsState'

export default {
  components: {

  },
  setup() {
    const {
      openBatchCounts,
    } = useStatisticsCardState()
    return {
      openBatchCounts,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    formatNumber(number) {
      if (typeof number !== 'number' || Number.isNaN(number)) {
        return '0'
      }
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
  },
}
</script>

<style lang="scss" scoped>

.statistics-card {
  background-color: rgb(216, 226, 234);
  color: rgb(35, 38, 41);
  display: block;
}

.statistics-card {
  padding: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 2px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@keyframes pulse {
  0% {
    border-color: rgb(215, 2, 119);
    border-radius: 2px;
  }
  50% {
    border-color: rgb(138, 29, 125);
    border-radius: 2px;
  }
  100% {
    border-color: rgb(215, 2, 119);
    border-radius: 2px;
  }
}

.pulsing-border {
  border: 2px solid;
  padding: 12px;
  background-color: rgba(232, 20, 66, 0.26) !important;
  color: rgb(211, 181, 213);
  animation: pulse 3s infinite; /* Adjust the duration as needed */
}

</style>
