/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */
import { isDevelopment } from '@/libs/env.js'
import {
  onBeforeMount, ref, watch,
} from '@vue/composition-api'
import printerAgentService from '@/http/requests/printer/printer-agent'
import printerserver from '@/http/requests/printer/printerserver'

export default function usePrinterHelper() {
  const printers = ref([])
  const labels = ref([])
  const selectedPrinter = ref({})
  const hasNewVersion = ref(false)

  async function checkVersion() {
    if (isDevelopment) {
      hasNewVersion.value = false
      return
    }
    try {
      const agentVersion = await printerAgentService.getAgentVersion()
      const newVersion = await printerserver.getSystemDataByKey('Version')
      if (agentVersion && newVersion) {
        hasNewVersion.value = newVersion.value > agentVersion
      }
    } catch (error) {
    }
  }

  async function getAvailablePrinters() {
    if (isDevelopment) {
      return
    }
    printerAgentService.availablePrinters()
      .then(result => {
        printers.value.length = 0
        printers.value = result.data
      })
  }

  onBeforeMount(async () => {
    await getAvailablePrinters()
    await checkVersion()
  })

  async function getLabels(vendorName) {
    const result = await printerAgentService.labelsByVendor(vendorName)
    labels.value.length = 0
    labels.value = result.data
  }

  watch(() => selectedPrinter,
    (newValue, oldValue) => {
      getLabels(newValue.value.VendorName)
    },
    { deep: true })

  return {
    selectedPrinter,
    printers,
    labels,
    getLabels,
    hasNewVersion,
  }
}
