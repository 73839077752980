/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import staxService from '@/http/requests/external/stax'
import Pager from '@core/dev-extreme/utils/pager'
import moment from 'moment'

// eslint-disable-next-line import/no-dynamic-require
const getCardTypeLink = type => require(`@/assets/images/svg/cards/${type.toLowerCase()}.svg`)

const configureSort = sort => {
  if (sort && sort.length > 0) {
    const sortSelectort = sort[0]
    return {
      sort: sortSelectort.selector,
      order: sortSelectort.desc ? 'DESC' : 'ASC',
    }
  }
  return {
    sort: 'created_at',
    order: 'DESC',
  }
}

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)

    const sort = configureSort(loadOptions.sort)
    let filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    filters = {
      ...filters,
      startDate: moment(filters.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(filters.endDate).format('YYYY-MM-DD HH:mm:ss'),
      page: pager.page + 1,
      per_page: loadOptions.take,
      ...sort,
    }
    const response = await staxService.getTransactions(filters)
    return {
      data: response.data,
      totalCount: response.total,
    }
  },
})

const transactionDataSource = new DataSource({
  store: store,
  map: dataItem => {
    const item = { ...dataItem }
    item.last_four = `****-****-****-${item.last_four}`
    const cardType = item.payment_method.card_type ? item.payment_method.card_type : 'ach'
    const cardLogoLink = getCardTypeLink(cardType)
    item.card_type = cardLogoLink
    return item
  },
})

export {
  transactionDataSource as TransactionDataSource,
}
