<template>
  <div v-if="userCompanyType === 'customer'" class="text-left">
    <span class="text-muted">
      My Prep Days
    </span>
    <div class="d-flex">
      <div v-for="day in weekDays" :key="day"
        class="p-half border d-flex justify-content-center"
        style="width: 42px; border-radius: 6px!important; margin: 1px"
        :class="{'bg-success text-white' : checkAssignedDays(day)}"
  >
        {{ shortenDayName(day) }}
      </div>
    </div>
  </div>
</template>

<script>
import companyService from '@/http/requests/company/companyService'
import useCurrentUser from '@/libs/app/current-user'

export default {
  setup() {
    const { userCompanyId, userCompanyType } = useCurrentUser()

    return {
      userCompanyId,
      userCompanyType,
    }
  },
  data() {
    return {
      workingDays: [],
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    }
  },
  async mounted() {
    const response = await companyService.getWorkingDaysForDashboard(this.userCompanyId)
    this.workingDays = response || []
  },
  methods: {
    checkAssignedDays(day) {
      return this.workingDays.includes(day)
    },
    shortenDayName(val) {
      return val.substring(0, 3)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
