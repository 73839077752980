<template>
    <div class="statistics-card h-100 col">
      <div class="d-flex flex-column flex-fill h-100">
          <div>
            <strong style="font-size: 18px; font-weight: 900;">
              Item Tracking
            </strong>
          </div>
          <div>
            <small class="d-block">
              Enter
              <strong class="text-warning">
                tracking number or UPC
              </strong>
              to check your items delivery status.
            </small>
          </div>
          <div class="mt-auto">
            <dx-util-text-box
              id="searchTextId"
              v-model="searchText"
              class="w-100 custom-color"
              :show-clear-button="true"
              @enterKey="onTrackItem"
              @keyUp="onCancel"
            >
              <DxTextBoxButton
                :options="searchButton"
                name=""
                location="after"
              />
            </dx-util-text-box>
            <dx-util-button v-show="trackingStatus.creationTime" icon="box" type="success" class="w-100 mt-half" text="Show Box Content" hint="Show box content" @click="getPackageContent" />
          </div>
          <div>
            <div v-if="showNotDelivered">
              <div class="badge badge-light-danger w-100 mt-1" style="font-size: 16px;">
                Not Delivered Yet
              </div>
            </div>
            <div v-else-if="hasPermission && trackingStatus.creationTime" class="badge badge-light-success text-left w-100 mt-1" style="font-size: 16px !important">
              <div>
                <i class="bi bi-truck text-success" style="font-size: 16px !important" />
                <span>
                  Delivered on
                </span>
                <strong>
                  {{ getDate(trackingStatus.creationTime) || '-' }}
                </strong>
              </div>
              <div class="text-info">
                <i class="bi bi-basket" style="font-size: 16px !important" />
                <span>
                  Batch Name
                </span>
                <strong>
                  {{ trackingStatus.batchName || '-' }}
                </strong>
              </div>
              <div class="text-warning">
                <i class="bi bi-basket" style="font-size: 16px !important" />
                <span>
                  Scanned By
                </span>
                <strong>
                  {{ trackingStatus.scannedBy || '-' }}
                </strong>
              </div>
              <div class="text-danger">
                <i class="bi bi-pin-map-fill" style="font-size: 16px !important" />
                <span>
                  Location
                </span>
                <strong>
                  {{ trackingStatus.warehouseLocationName || '-' }}
                </strong>
              </div>
            </div>
            <div v-else-if="trackingStatus.creationTime" class="row align-items-center px-1">
              <div class="col-12 col-lg-6 px-1 badge badge-light-success d-flex justify-content-center align-items-center" style="padding-top: 9px;padding-bottom: 9px;">
                <i class="bi bi-box-fill text-success mb-half mr-half" style="font-size:20px;"></i>
                <span class="ml-1">
                  Delivered on {{ getDate(trackingStatus.creationTime) }}
                </span>
              </div>
            </div>
            <package-content :component-id="packageContentCompId" :package-content="packageContent" />
          </div>
        </div>
      </div>
</template>

<script>
import ItemSortFilter from '@/http/models/search-filters/ItemSortFilter'
import packageScanService from '@/http/requests/inbound/packageScanService'
import { formatDate } from '@core/utils/filter'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import { isValidUpc } from '@/views/apps/ship/validationItem'
import { v4 as uuidv4 } from 'uuid'
import sortItemService from '@/http/requests/inbound/sort-item.service'
import Pager from '@/@core/dev-extreme/utils/pager'

export default {
  components: {
    DxTextBoxButton,
    'package-content': () => import('./PackageContent.vue'),
  },
  data() {
    return {
      searchText: '',
      showNotDelivered: false,
      trackingStatus: {
        id: null,
        creationTime: null,
      },
      searchButton: {
        icon: 'search',
        text: 'Search',
        type: 'default',
        onClick: () => {
          this.onTrackItem()
        },
      },
      packageContent: [],
      packageContentCompId: '',
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    isUpc() {
      return isValidUpc(this.searchText)
    },
  },
  methods: {
    onTrackItem() {
      this.searchText = this.searchText.trim()
      if (this.searchText === '') return

      this.fetchByTracking()
    },
    async fetchByTracking() {
      this.showNotDelivered = false
      const result = await packageScanService.fetchByTracking(this.searchText)
      const { data } = result
      this.trackingStatus = data
      if (data === null) {
        this.showNotDelivered = true
        this.trackingStatus = {
          creationTime: null,
        }
      }
    },
    async getPackageContent() {
      ItemSortFilter.setDefaultFilters()
      if (this.isUpc) {
        ItemSortFilter.q = this.searchText
      } else {
        ItemSortFilter.packageScanId = this.trackingStatus.id
      }
      ItemSortFilter.status = 'ALL'
      const filters = ItemSortFilter.getFilters()
      const pager = new Pager()
      const response = await sortItemService.trackItemsByQuery(filters, pager.staticPageable)
      const packageContent = response.data.body.content
      if (packageContent?.length === 0) {
        this.$swal({
          icon: 'warning',
          title: 'Package content is not available!',
          text: 'We could not find any item related to the search text',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        this.packageContentCompId = uuidv4()
        this.packageContent = packageContent
      }
    },
    onCancel(e) {
      const escapeKey = 27
      if (e.event.which === escapeKey) {
        this.trackingStatus.creationTime = null
        this.showNotDelivered = null
        this.searchText = ''
      }
    },
    getDate(date) {
      return formatDate(date)
    },
  },
}
</script>

<style lang="scss">
.statistics-card {
  background-color: rgb(197, 235, 208);
  color: rgb(35, 38, 41);
  display: flex;
  align-items: start;
  padding: 12px;
  border-radius: 5px;
  width: auto;
  border: 0.1px solid;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.custom-color {
    background-color: rgb(218, 240, 211);
    border-radius: 4px;
  }

@media screen and (max-width: 1280px) {
 .statistics-card {
    width: 100%;
 }
}
</style>
