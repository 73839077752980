import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async generateUserAuthorizationUrl() {
    const result = await axios.get(endpoints.ebay.controller.generateUserAuthorizationUrl())
    return result.data.body
  },

  async exchangeCodeForAccessToken(data) {
    const result = await axios.post(endpoints.ebay.controller.exchangeCodeForAccessToken(), data)
    return result
  },

  async connectStore(data) {
    const result = await axios.post(endpoints.ebay.controller.connectStore(), data)
    return result
  },

}
