<template>
  <div class="d-inline d-flex mx-0 px-0 h-100 align-items-center">
    <div class="statistics-card flex-grow-1">
      <div class="d-flex justify-content-between">
        <div class="pt-half d-flex">
          <div>
            <strong style="font-size: 18px; font-weight: 900;" class="d-block">
              Pending Companies
            </strong>
            <small class="">
              Please contact and activate
                <router-link :to="{ name: 'company-management', query: { status: 'pending' } }" class="mx-half text-warning">
                  the pending users
                </router-link>
                to maximize engagement and unlock the PrepShipHub's full potential.
            </small>
          </div>
        </div>
        <div class="d-flex align-items-center text-center" style="min-width: 160px;">
          <div class="px-1">
            <small>Last 10 days</small>
            <div class="text-black" style="font-size: 24px;font-weight: 800;">
              {{ last10DaysPendingCompanyCount }}
            </div>
          </div>
          <div class="px-1">
            <small>Total</small>
            <div class="text-black" style="font-size: 24px;font-weight: 800;">
              {{ pendingCompanyCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import companyService from '@/http/requests/company/companyService'

export default {
  data() {
    return {
      pendingCompanyCount: 0,
      last10DaysPendingCompanyCount: 0,
    }
  },
  mounted() {
    this.getPendingCompanyCount()
  },
  methods: {
    async getPendingCompanyCount() {
      const result = await companyService.getCompanyCount()
      this.pendingCompanyCount = result?.totalCompanyCount
      this.last10DaysPendingCompanyCount = result?.lastTenDaysCompanyCount
    },
  },
}
</script>

<style lang="scss" scoped>

.statistics-card {
  background-color: rgb(216, 226, 234);
  color: rgb(35, 38, 41);
  display: block;
  padding: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 2px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pulsing-border {
  border: 2px solid;
  padding: 12px;
  background-color: #f08080 !important;
  color: rgb(242, 237, 243);
  animation: pulse 3s infinite; /* Adjust the duration as needed */
}

</style>
