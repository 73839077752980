<template>
  <div class="">
    <dx-util-text-box
      :ref="`textbox-referenceId-${shipmentId}`"
      :value="referenceId"
      width="140"
      @enter-key="handleSubmit"
      @value-changed="updateRefId"
    >
      <dx-text-box-button
        :name="`referenceId-${shipmentId}`"
        :options="{
          icon: 'save',
          type: 'success',
          elementAttr: { id: `referenceId-${shipmentId}` },
          onClick: () => handleSubmit()
          }"
        location="after"
      />
    </dx-util-text-box>
  </div>
</template>

<script>
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    shipmentId: {
      type: Number,
      required: true,
    },
    referenceId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      form: {
        id: null,
        referenceId: '',
      },
    }
  },
  mounted() {
    this.form.referenceId = this.referenceId
  },
  methods: {
    async handleSubmit() {
      this.form.id = this.shipmentId
      await fbaShipmentService.updateSingleBatch(this.form)
      this.$emit('emit-reference-id-updated')
      this.form.id = null
    },
    updateRefId(e) {
      this.form.referenceId = e.value
    },
  },

}
</script>

<style lang="scss" scoped>
  .btn-assign{
    color: #fff !important;
    width: 100% !important;
    .dx-button-content {
      padding: 4px 4px 4px !important;
    }
  }

</style>
