<template>
   <div class="statistics-card h-100 col">
      <div class="d-flex flex-column flex-fill h-100">
        <div>
          <strong style="font-size: 18px; font-weight: 900;">
            Invite Your Customer
          </strong>
        </div>
        <div>
          <small class="d-block">
            You can share the link for your customers to register in PrepShipHub.
          </small>
        </div>
        <div class="mt-auto">
          <dx-util-button class="w-100" text="Share Invitation Link" type="default" @click="openLinkShare" />
        </div>
      </div>
      <invitation-link :component-id="invitationComponentId" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    'invitation-link': () => import('@/views/apps/company/company-list/InvitationLink.vue'),
  },
  data() {
    return {
      invitationComponentId: '',
    }
  },
  methods: {
    openLinkShare() {
      this.invitationComponentId = uuidv4()
    },
  },
}
</script>

<style lang="scss" scoped>

.statistics-card {
  background-color: rgb(197, 235, 208);
  color: rgb(35, 38, 41);
  display: flex;
  align-items: start;
  padding: 8px;
  border-radius: 5px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
}

@media screen and (max-width: 1280px) {
 .statistics-card {
    width: 100%;
 }
}

</style>
