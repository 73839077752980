<template>
  <div class="d-inline d-flex mx-0 px-0 h-100 " :role="hasPermission ? 'button': ''" @click="goToPath(stats.path)">
    <div class="statistics-card flex-grow-1" :class="{'pulsing-border': count >= stats.threshold}">
      <div class="d-flex justify-content-between">
        <div class="pt-half d-flex ">
          <div class="">
            <div class="d-flex">
              <strong style="font-size: 18px; font-weight: 900;" class="d-block">
                {{ stats.title }}
              </strong>
            </div>
            <small class="d-block">
              {{ stats.description }} <strong>Click for Details</strong>
            </small>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div v-for="item in stats.children" :key="item.id" class="mx-1">
            <div>
              <div class="text-center">
                <small class="mx-auto">
                  {{ item.title }}
                </small>
              </div>
              <div class="text-center text-black">
                <strong class="mx-auto" style="font-size: 22px;">
                  {{ getCountsByStatsType(item.statsType) }}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import useStatisticsCardState from './useStatisticsState'

export default {
  props: {
    stats: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { actionCounts } = useStatisticsCardState()

    const count = computed(() => {
      const statsType = props.stats.children[0].statsType
      const matchingItem = actionCounts.value?.find(el => el[statsType] !== undefined)
      return matchingItem ? matchingItem[statsType] : 0
    })

    function getCountsByStatsType(statsType) {
      const matchingItem = actionCounts.value?.find(el => el[statsType] !== undefined)
      return matchingItem ? matchingItem[statsType] : 0
    }

    return {
      count,
      actionCounts,
      getCountsByStatsType,
    }
  },
  computed: {
    hasPermission() {
      return this.stats.permission ? this.$can('read', this.stats.permission) : true
    },
  },
  methods: {
    goToPath(path) {
      if (this.hasPermission) {
        this.$router.push({ name: path })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.statistics-card {
  background-color: rgb(216, 226, 234);
  color: rgb(35, 38, 41);
  display: block;
  padding: 12px;
  border-radius: 2px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pulsing-border {
  border: 2px solid;
  padding: 12px;
  background-color: #f07167 !important;
  animation: pulse 3s infinite; /* Adjust the duration as needed */
}

</style>
