<template>
  <div class="statistics-card col h-100">
    <div class="d-flex flex-column flex-fill h-100">
        <div>
          <strong style="font-size: 18px; font-weight: 900;">
            Upload Inbound Shipment Plan
          </strong>
        </div>
        <div>
          <small class="d-block">
            You can upload your shipment items for inbound shipping plans here.
          </small>
        </div>
        <div class="mt-auto">
          <dx-util-button class="w-100" text="Upload Shipping Plan" type="default" @click="$router.push({name: 'file-upload'})" />
        </div>
      </div>
  </div>
</template>

<script>
import companyService from '@/http/requests/company/companyService'

export default {
  data() {
    return {
      pendingCompanyCount: 0,
      last10DaysPendingCompanyCount: 0,
    }
  },
  mounted() {
    this.getPendingCompanyCount()
  },
  methods: {
    async getPendingCompanyCount() {
      const result = await companyService.getCompanyCount()
      this.pendingCompanyCount = result?.totalCompanyCount
      this.last10DaysPendingCompanyCount = result?.lastTenDaysCompanyCount
    },
  },
}
</script>

<style lang="scss" scoped>

.statistics-card {
  background-color: rgb(197, 235, 208);
  color: rgb(35, 38, 41);
  display: flex;
  align-items: start;
  padding: 12px;
  border-radius: 5px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1280px) {
 .statistics-card {
    width: 100%; // Make the card take full width on screens less than 1200px
 }
}
</style>
