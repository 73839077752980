<template>
  <div v-if="count > 0" class="d-inline d-flex mx-0 px-0 h-100 align-items-center">
    <div class="statistics-card flex-grow-1" :class="{'pulsing-border': count > 0}">
      <div class="d-flex justify-content-between">
        <div class="pt-half d-flex">
          <div>
            <strong style="font-size: 18px; font-weight: 900;" class="d-block">
              Missing Amazon PO/Reference IDs
            </strong>
            <small class="d-block">
              Please update the missing Amazon PO/Reference IDs immediately to prevent
              delays in freight service.
              <strong role="button" @click="$router.push({name: 'outbound-shipping'})">Click for FBA Shipment Batches</strong>
            </small>
          </div>
        </div>
        <div class="d-flex">
          <div class="text-center ml-2 mr-1 pt-2">
            <dx-util-button id="missingIds" text="Update" type="success" />
          </div>
          <div class="text-center ml-2 mr-1" style="width: 40px;">
            <small>Count</small>
            <strong class="mx-auto" style="font-size: 22px;">
              {{ count }}
            </strong>
          </div>
        </div>
      </div>
    </div>
    <dx-util-popover
      :width="400"
      :visible="false"
      target="#missingIds"
      position="right"
      show-event="dxhoverstart"
      hide-event="dxclick"
      @hiding="getLTLReferenceIds"
    >
      <div class="pt-1">
        <strong class="text-warning">
          Update the Amazon PO/Reference IDs
        </strong>
        <table style="width: 100%;" class="mt-1">
          <tbody>
            <tr v-for="reference in missingReferenceIds" :key="reference.id">
              <td>
                {{ reference.shipmentId }} <copy-button :copy-value="reference.shipmentId" />
              </td>
              <td class="">
                <dashboard-reference-id-setter :shipment-id="reference.id" :reference-id="reference.referenceId" @emit-reference-id-updated="getLTLReferenceIds" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </dx-util-popover>
  </div>
</template>

<script>
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import DashboardReferenceIdSetter from './DashboardReferenceIdSetter.vue'

export default {
  components: {
    'dashboard-reference-id-setter': DashboardReferenceIdSetter,
  },
  data() {
    return {
      missingReferenceIds: [],
      count: 0,
    }
  },
  mounted() {
    this.getLTLReferenceIds()
  },
  methods: {
    async getLTLReferenceIds() {
      this.missingReferenceIds = await outboundShippingService.getMissingReferenceIds()
      this.count = this.missingReferenceIds?.length
    },
  },
}
</script>

<style lang="scss" scoped>

.statistics-card {
  background-color: rgb(216, 226, 234);
  color: rgb(35, 38, 41);
  display: block;
}

.statistics-card {
  padding: 12px;
  border-radius: 2px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pulsing-border {
  border: 2px solid;
  padding: 12px;
  background-color: #f07167 !important;
  animation: pulse 3s infinite; /* Adjust the duration as needed */
}

td {
  padding-top: 8px;
  padding-bottom: 8px;
}

tr {
  border-bottom:  1px solid rgba(71, 71, 118, 0.5);

}

</style>
