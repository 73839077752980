<template>
  <dx-util-popup
    ref="labelFormPopupRef"
    :hide-on-outside-click="false"
    :show-title="true"
    :drag-enabled="false"
    position="center"
    title="Shipping Label Form"
    @shown="onShownLabelFormPopup"
    @hidden="onHiddenLabelFormPopup"
  >
    <template #content>
      <dx-util-scroll-view>
        <div class="row  match-height">
          <div class="col-12 col-sm-6">
            <div class="card card-psh border">
              <div class="bg-light-primary rounded-top py-half px-1">
                <div class="psh-header mb-0 d-flex align-items-center px-0">
                  <div class="psh-title border-0 mr-0">
                    <i class="bi bi-send-fill text-h1" style="font-size: 2rem;" />
                  </div>
                    <h4 class="card-title mb-25 text-warning">
                      Sender Information
                    </h4>
                </div>
              </div>
              <div class="card-body">
                <dx-util-validation-group :ref="senderAddressValidationRef">
                  <div class="d-flex align-items-center">
                    <dx-util-number-box
                      v-if="hasPermission"
                      v-model="accountNo"
                      type="default"
                      label="Account No"
                      label-mode="floating"
                      class="mr-1"
                      :on-enter-key="getAddressByAccount"
                    />
                    <dx-util-button
                      v-if="hasPermission"
                      text="Get Address"
                      type="default"
                      class="mt-half mr-1"
                      @click="getAddressByAccount"
                    />
                    <dx-util-select-box
                      v-model="selectedStore"
                      :data-source="storeList"
                      display-expr="text"
                      value-expr="value"
                      item-template="item"
                      label-mode="floating"
                      label="Store"
                      class="w-100"
                      @value-changed="setStoreId"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          message="Store information is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-select-box>
                  </div>
                  <div class="d-flex align-items-center">
                    <dx-util-select-box
                      v-model="shipFromId"
                      :disabled="isNewFromAddress"
                      :data-source="fromAddresses"
                      display-expr="text"
                      value-expr="id"
                      item-template="item"
                      label-mode="floating"
                      label="Ship From"
                      class="mb-half flex-grow-1"
                      :show-clear-button="true"
                      @selection-changed="onShipFromIdChanged"
                    >
                      <template #item="{ data }">
                        <div v-html="data.textHtml" />
                      </template>
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="!isNewFromAddress"
                          message="Ship From is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-select-box>
                    <div class="p-half ml-2">
                      <span class="text-warning">New Address</span>
                      <dx-util-switch
                        v-model="isNewFromAddress"
                        :on-value-changed="onNewFromAddressChanged"
                        class="my-half ml-half"
                      />
                    </div>
                  </div>
                  <div v-show="isNewFromAddress" class="m-0 py-half">
                    <span class="text-warning">New Address Details</span>
                    <dx-util-text-box
                      v-model="fromAddress.name"
                      label="Sender"
                      label-mode="floating"
                      :input-attr="{autocomplete: 'chrome-off'}"
                      class="mb-1"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="isNewFromAddress"
                          message="Sender is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-text-box>
                    <dx-util-text-box
                      v-model="fromAddress.street1"
                      label="Street address"
                      label-mode="floating"
                      class="mb-half"
                      :input-attr="{autocomplete: 'chrome-off'}"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="isNewFromAddress"
                          message="Street address is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-text-box>
                    <dx-util-text-box
                      v-model="fromAddress.street2"
                      label="Apartment, suite, etc."
                      label-mode="floating"
                      class="mb-half"
                      :input-attr="{autocomplete: 'chrome-off'}"
                    />
                    <div class="d-flex align-items-center">
                      <dx-util-text-box
                        v-model="fromAddress.city"
                        label="City"
                        label-mode="floating"
                        class="mr-1"
                        :input-attr="{autocomplete: 'chrome-off'}"
                      >
                        <dx-util-validator>
                          <dx-util-required-rule
                            v-if="isNewFromAddress"
                            message="City is a required field"
                          />
                        </dx-util-validator>
                      </dx-util-text-box>
                      <dx-util-text-box
                        v-model="fromAddress.zip"
                        label="Zip Code"
                        label-mode="floating"
                        class="mr-1"
                        :input-attr="{autocomplete: 'chrome-off'}"
                      >
                        <dx-util-validator>
                          <dx-util-required-rule
                            v-if="isNewFromAddress"
                            message="Zip Code is a required field"
                          />
                        </dx-util-validator>
                      </dx-util-text-box>
                      <dx-util-select-box
                        v-model="fromAddress.country"
                        :data-source="countriesDetail"
                        value-expr="code"
                        display-expr="name"
                        label="Country"
                        label-mode="floating"
                        :search-enabled="true"
                        :input-attr="{autocomplete: 'chrome-off'}"
                      >
                        <dx-util-validator>
                          <dx-util-required-rule
                            v-if="isNewFromAddress"
                            message="Country is a required field"
                          />
                        </dx-util-validator>
                      </dx-util-select-box>
                      <dx-util-text-box
                        v-if="!isSenderUSAorCanada"
                        v-model="fromAddress.state"
                        label="State"
                        label-mode="floating"
                        class="ml-1"
                        :input-attr="{autocomplete: 'chrome-off'}"
                      >
                        <dx-util-validator>
                          <dx-util-required-rule
                            v-if="isNewFromAddress"
                            message="State is a required field"
                          />
                        </dx-util-validator>
                      </dx-util-text-box>
                      <dx-util-select-box v-else v-model="fromAddress.state"
                        :items="senderStates"
                        label="State"
                        label-mode="floating"
                        class="ml-1"
                        :search-enabled="true"
                        :input-attr="{autocomplete: 'chrome-off'}"
                      >
                        <dx-util-validator>
                          <dx-util-required-rule
                            v-if="isNewFromAddress"
                            message="State is a required field"
                          />
                        </dx-util-validator>
                      </dx-util-select-box>
                    </div>
                    <div class="pt-half">
                      <dx-util-text-box
                        v-model="fromAddress.phone"
                        label="Phone Number"
                        label-mode="floating"
                        hint="For FEDEX shipments phone number is required"
                        :input-attr="{autocomplete: 'chrome-off'}"
                      >
                      <dx-util-validator>
                          <dx-util-required-rule
                            v-if="isNewFromAddress"
                            message="Phone number is a required field"
                          />
                        </dx-util-validator>
                      </dx-util-text-box>
                    </div>
                  </div>
                  <div class="pt-half">
                    <dx-util-select-box
                      v-if="!isNewFromAddress"
                      v-model="selectedPhone"
                      :data-source="phones"
                      display-expr="text"
                      value-expr="id"
                      item-template="item"
                      label-mode="floating"
                      label="Phone Number"
                      class="mb-1 flex-grow-1"
                      :on-custom-item-creating="onCustomItemCreating"
                      :accept-custom-value="false"
                      :show-clear-button="true"
                      :input-attr="{autocomplete: 'chrome-off'}"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="!shipToId"
                          message="Phone is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-select-box>
                  </div>
                  <div>
                    <span class="text-warning">
                      Notes
                    </span>
                    <dx-util-text-area
                      v-model="notes"
                      max-length="60"
                      class="col-12 mr-1 mt-half"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="!shipToId"
                          message="Notes is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-text-area>
                  </div>
                </dx-util-validation-group>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card card-psh border">
              <div class="bg-light-primary rounded-top py-half px-1">
                <div class="psh-header mb-0 d-flex align-items-center px-0">
                  <div class="psh-title border-0 mr-0">
                    <i class="bi bi-send text-h1" style="font-size: 2rem;" />
                  </div>
                    <h4 class="card-title mb-25 text-warning">
                      Receiver Information
                    </h4>
                </div>
              </div>
              <div class="card-body">
                <dx-util-validation-group :ref="receiverAddressValidationRef">
                  <dx-util-select-box
                    v-model="selectedAmazonAddress"
                    :data-source="amazonAddresses"
                    display-expr="text"
                    value-expr="text"
                    search-mode="contains"
                    :show-data-before-search="false"
                    :search-timeout="200"
                    :search-enabled="true"
                    search-expr="code"
                    :min-search-length="0"
                    label-mode="floating"
                    label="Amazon Address"
                    class="mb-1"
                    :show-clear-button="true"
                    :input-attr="{autocomplete: 'chrome-off'}"
                    @value-changed="fillAddressFields"
                  />
                  <div class="d-flex align-items-center">
                    <dx-util-select-box
                      v-model="shipToId"
                      :data-source="fromAddresses"
                      display-expr="text"
                      value-expr="id"
                      item-template="item"
                      label-mode="floating"
                      label="Ship To Existing Address"
                      class="mb-1 flex-grow-1"
                      :show-clear-button="true"
                      @selection-changed="onShipToAddressChange"
                    >
                      <template #item="{ data }">
                        <div v-html="data.textHtml" />
                      </template>
                    </dx-util-select-box>
                  </div>
                  <dx-util-text-box
                    v-model="toAddress.name"
                    label="Receiver"
                    label-mode="floating"
                    class="mb-1"
                    :input-attr="{autocomplete: 'chrome-off'}"
                  >
                    <dx-util-validator>
                      <dx-util-required-rule
                        v-if="!shipToId"
                        message="Receiver is a required field"
                      />
                    </dx-util-validator>
                  </dx-util-text-box>
                  <dx-util-text-box
                    v-model="toAddress.street1"
                    label="Street address"
                    label-mode="floating"
                    class="mb-1"
                    :input-attr="{autocomplete: 'chrome-off'}"
                  >
                    <dx-util-validator>
                      <dx-util-required-rule
                        v-if="!shipToId"
                        message="Street address is a required field"
                      />
                    </dx-util-validator>
                  </dx-util-text-box>
                  <dx-util-text-box
                    v-model="toAddress.street2"
                    label="Apartment, suite, etc."
                    label-mode="floating"
                    class="mb-1"
                    :input-attr="{autocomplete: 'chrome-off'}"
                  />
                  <div class="d-flex align-items-center">
                    <dx-util-text-box
                      v-model="toAddress.city"
                      label="City"
                      label-mode="floating"
                      :input-attr="{autocomplete: 'chrome-off'}"
                      class="mr-1"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="!shipToId"
                          message="City is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-text-box>
                    <dx-util-text-box
                      v-model="toAddress.zip"
                      label="Zip Code"
                      label-mode="floating"
                      class="mr-1"
                      :input-attr="{autocomplete: 'chrome-off'}"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="!shipToId"
                          message="Zip Code is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-text-box>
                    <dx-util-select-box
                      v-model="toAddress.country"
                      label="Country"
                      auto-complete="none"
                      :input-attr="{autocomplete: 'chrome-off'}"
                      label-mode="floating"
                      :data-source="countriesDetail"
                      value-expr="code"
                      display-expr="name"
                      :search-enabled="true"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="!shipToId"
                          message="Country is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-select-box>
                    <dx-util-text-box
                      v-if="!isReceiverUSAorCanada"
                      v-model="toAddress.state"
                      label="State"
                      label-mode="floating"
                      :search-enabled="true"
                      :input-attr="{autocomplete: 'chrome-off'}"
                      class="ml-1"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          v-if="!shipToId"
                          message="State is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-text-box>
                    <dx-util-select-box v-else v-model="toAddress.state"
                      class="ml-1"
                      label="State"
                      label-mode="floating"
                      :items="receiverStates"
                      :search-enabled="true"
                      :input-attr="{autocomplete: 'chrome-off'}"
                    >
                      <dx-util-validator>
                          <dx-util-required-rule
                            v-if="!shipToId"
                            message="State is a required field"
                          />
                        </dx-util-validator>
                    </dx-util-select-box>
                  </div>
                  <div class="d-flex align-items-center mr-1 mb-1">
                    <dx-util-text-box
                      v-model="toAddress.phone"
                      label="Phone"
                      label-mode="floating"
                      class="col-6 mr-1"
                      :input-attr="{autocomplete: 'chrome-off'}"
                    />
                    <dx-util-text-box
                      v-model="toAddress.email"
                      label="Email"
                      label-mode="floating"
                      class="col-6 mr-1"
                      :input-attr="{autocomplete: 'chrome-off'}"
                    />
                  </div>
                </dx-util-validation-group>
              </div>
            </div>
          </div>
          <div v-if="internationalOrder" class="col-12 col-sm-6">
            <customs-items :reference-key="referenceKey" :reference-id="referenceId" :is-fbm-force="false" class="w-100" />
          </div>
          <div class="col-12" :class="{'col-sm-6': internationalOrder}">
            <div class="card card-psh border">
              <div class="bg-light-primary rounded-top py-half px-1">
                <div class="psh-header mb-0 d-flex align-items-center px-0">
                  <div class="psh-title border-0 mr-0">
                    <i class="bi bi-box text-h1" style="font-size: 2rem;" />
                  </div>
                  <h4 class="card-title mb-25 text-warning">
                    Box Information
                  </h4>
                  <div class="flex-grow-1"></div>
                  <div class="mr-half">
                    <deposit-widget />
                  </div>
                  <div>
                    <current-credit-widget />
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="d-flex">
                  <dx-util-validation-group
                    :ref="packValidationRef"
                    class="d-flex align-items-start my-1"
                  >
                    <dx-util-number-box
                      v-model="pack.count"
                      type="default"
                      label="Count"
                      :width="100"
                      label-mode="floating"
                      class="mr-1"
                      :min="1"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          message="Count is required a field"
                        />
                      </dx-util-validator>
                    </dx-util-number-box>
                    <!-- <dx-util-text-box v-model="pack.description" type="default" label="Description" label-mode="floating" class="mr-1" /> -->
                    <dx-util-number-box
                      v-model="pack.length"
                      type="default"
                      label="Length (in)"
                      format="#0 in"
                      label-mode="floating"
                      class="mr-1"
                      :min="1"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          message="Length is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-number-box>
                    <dx-util-number-box
                      v-model="pack.height"
                      type="default"
                      label="Height (in)"
                      format="#0 in"
                      label-mode="floating"
                      class="mr-1"
                      :min="1"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          message="Height is a required field"
                        />
                      </dx-util-validator>
                    </dx-util-number-box>
                    <dx-util-number-box
                      v-model="pack.width"
                      type="default"
                      label="Width (in)"
                      format="#0 in"
                      label-mode="floating"
                      class="mr-1"
                      :min="1"
                    >
                      <dx-util-validator>
                        <dx-util-required-rule
                          message="Width is required a field"
                        />
                      </dx-util-validator>
                    </dx-util-number-box>
                    <dx-util-number-box
                      v-model="pack.weightLb"
                      type="default"
                      label="Weight (lb)"
                      format="#0 lb"
                      label-mode="floating"
                      class="mr-1"
                      :min="0"
                    />
                    <dx-util-number-box
                      v-model="pack.weightOz"
                      type="default"
                      label="Weight (oz)"
                      format="#0 oz"
                      label-mode="floating"
                      :min="0"
                      class="mr-1"
                    />
                  </dx-util-validation-group>
                  <div class="d-flex align-items-center mt-half">
                    <dx-util-button
                      type="success"
                      icon="plus"
                      class="mr-1"
                      @click="addBox"
                    />
                    <dx-util-button
                      type="default"
                      text="Get Rates"
                      class="px-sm"
                      @click="getRatesForPackageBoxes"
                    />
                  </div>
                </div>
                <dx-data-grid
                  id="boxesGrid"
                  ref="boxesGridRef"
                  :data-source="boxesDataSource"
                  :column-auto-width="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :show-borders="true"
                  :row-alternation-enabled="true"
                >
                  <dx-scrolling column-rendering-mode="standard" />
                  <dx-editing
                    :allow-updating="true"
                    mode="cell"
                    :use-icons="true"
                  />

                  <dx-column data-field="count" caption="Count" :width="80" />
                  <!-- <dx-column
                data-field="description"
                caption="Description"
              /> -->
                  <dx-column data-field="length" caption="Length (in)" />
                  <dx-column data-field="height" caption="Height (in)" />
                  <dx-column data-field="width" caption="Width (in)" />
                  <dx-column data-field="weightLb" caption="Weight (lb)" />
                  <dx-column data-field="weightOz" caption="Weight (oz)" />
                  <dx-column
                    type="buttons"
                    name="action"
                    caption="Actions"
                    :width="80"
                    :allow-resizing="false"
                  >
                    <dx-button
                      icon="bi bi-x-square-fill"
                      hint="Delete Pack"
                      css-class="text-danger"
                      :on-click="deletePack"
                    />
                  </dx-column>
                  <!--Begin    ::DataGrid toolbar -->
                  <template #toolbarFilters>
                    <div class="d-flex flex-row align-items-center">
                      <div class="mr-1">
                        <dx-util-text-box
                          v-model="accountNo"
                          :show-clear-button="true"
                          mode="text"
                          placeholder="Account or Suite No"
                          :on-enter-key="searchByFilter"
                        />
                      </div>
                      <div class="mr-1">
                        <dx-util-select-box
                          v-model="selectedLabelType"
                          :data-source="shippingLabelTypes"
                          display-expr="text"
                          value-expr="value"
                          @value-changed="searchByFilter"
                        />
                      </div>
                      <div class="mr-1">
                        <dx-util-date-box
                          v-model="beginDate"
                          :max="endDate"
                          type="date"
                          @value-changed="searchByFilter"
                        />
                      </div>
                      <div class="mr-1">
                        <dx-util-date-box
                          v-model="endDate"
                          :min="beginDate"
                          type="date"
                          @value-changed="searchByFilter"
                        />
                      </div>
                    </div>
                  </template>
                  <!--End    ::DataGrid toolbar -->
                </dx-data-grid>
              </div>
            </div>
          </div>
          <dx-util-popup
            ref="rateListPopupRef"
            :hide-on-outside-click="false"
            :show-title="true"
            :drag-enabled="false"
            width="45vw"
            height="auto"
            position="center"
            title="Rate List"
          >
            <template #content>
              <div>
                <p>
                  Please <b class="text-warning">select an option </b> below and
                  click buy.
                </p>
                <dx-util-list
                  :data-source="rateList"
                  show-scrollbar="always"
                  selection-mode="single"
                  height="400"
                  width="100%"
                  :hover-state-enabled="true"
                  :focus-state-enabled="true"
                  :on-item-click="selectRate"
                  :use-native-scrolling="false"
                >
                  <template #item="{ data: item }">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex text-left w-100">
                        <img :src="item.imageUrl" alt="">
                        <div class="pl-1">
                          {{ item.serviceName }} <br>
                          Estimated delivery in {{ item.days }} days.
                        </div>
                      </div>
                      <div class="text-warning">
                        ${{ item.amount }}
                      </div>
                    </div>
                  </template>
                </dx-util-list>
                <div class="text-right">
                  <dx-util-button
                    text="Cancel"
                    type="danger"
                    class="m-1"
                    @click="closeRatePopup"
                  />
                  <dx-util-button
                    text="Buy"
                    type="default"
                    class=""
                    :disabled="rateNotSelected"
                    @click="buyRate"
                  />
                </div>
              </div>
            </template>
          </dx-util-popup>
        </div>
      </dx-util-scroll-view>
    </template>
  </dx-util-popup>
</template>

<script>
import commonService from '@/http/requests/common/commonEntityService'
import companyService from '@/http/requests/company/companyService'
import useCurrentUser from '@/libs/app/current-user'
import useCustomsItems from '@/views/apps/tools/components/useCustomsItems'
import shipmentLabelService from '@/http/requests/external/shipmentLabels.js'
import { Notify } from '@/@robustshell/utils'
// import { v4 as uuidv4 } from 'uuid'
import CurrentCreditWidget from '@/views/apps/finance/balances/CurrentCreditWidget.vue'
import DepositWidget from '@/views/apps/finance/balances/DepositWidget.vue'
import { countriesDetail, statesByCountry } from '@/http/requests/statics/locations'
import ItemActionTypeEnum from '@/enums/itemActionTypeEnum'

function initialState() {
  return {
    countriesDetail: countriesDetail.filter(country => {
      const allowedCountryCodes = ['USA', 'MX', 'CA']
      return allowedCountryCodes.includes(country.code)
    }),
    itemActions: Object.values(ItemActionTypeEnum),
    itemAction: Object.values(ItemActionTypeEnum)[0],
    statesByCountry,
    accountNo: null,
    companyName: '',
    fromAddresses: [],
    shipFromId: null,
    shipToId: null,
    boxesDataSource: [],
    rateList: [],
    fromAddress: {
      name: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'USA',
      phone: '',
    },
    toAddress: {
      name: '',
      phone: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'USA',
      email: '',
    },
    notes: '',
    pack: {
      count: 1,
      length: null,
      width: null,
      height: null,
      weightLb: 0,
      weightOz: 0,
      description: '',
    },
    packValidationRef: 'targetGroup1',
    senderAddressValidationRef: 'targetGroup2',
    receiverAddressValidationRef: 'targetGroup3',
    packageInputAttr: {},
    storeList: [],
    selectedStore: null,
    selectedRate: {},
    phones: [],
    selectedPhone: '',
    amazonAddresses: [],
    selectedAmazonAddress: null,
    isNewFromAddress: false,
    isNewToAddress: false,
    existingAddresses: [],
  }
}

export default {
  components: {
    'deposit-widget': DepositWidget,
    'current-credit-widget': CurrentCreditWidget,
    'customs-items': () => import('@/views/apps/tools/components/CustomsItems.vue'),
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    referenceKey: {
      type: String,
      default: 'LABEL_TOOL',
    },
    referenceId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { userCompanyId, userCompanyType, userAccountNo } = useCurrentUser()
    const { customsItemsState, setStoreId, customsDeclarationId } = useCustomsItems()

    return {
      userAccountNo,
      userCompanyId,
      userCompanyType,
      customsItemsState,
      setStoreId,
      customsDeclarationId,
    }
  },
  data() {
    return initialState()
  },
  computed: {
    labelFormPopupIns() {
      return this.$refs.labelFormPopupRef.instance
    },
    isReceiverUSAorCanada() {
      return this.toAddress.country === 'USA' || this.toAddress.country === 'CA'
    },
    isSenderUSAorCanada() {
      return this.fromAddress.country === 'USA' || this.fromAddress.country === 'CA'
    },
    fromEditorOptions() {
      return {
        dataSource: this.fromAddresses,
        displayExpr: 'textHtml',
        valueExpr: 'id',
        onValueChanged: e => {
          this.labelFormData.planId = e.value
        },
      }
    },
    validationGroup() {
      return this.$refs[this.packValidationRef].instance
    },
    senderAddressValidationGroup() {
      return this.$refs[this.senderAddressValidationRef].instance
    },
    receiverAddressValidationGroup() {
      return this.$refs[this.receiverAddressValidationRef].instance
    },
    rateNotSelected() {
      return Object.keys(this.selectedRate).length === 0
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    internationalOrder() {
      return this.toAddress.country !== this.fromAddress.country
    },
    senderStates() {
      return statesByCountry[this.fromAddress.country]
    },
    receiverStates() {
      return statesByCountry[this.toAddress.country]
    },
  },
  watch: {
    componentId: {
      immediate: false,
      handler(current) {
        if (current) {
          this.createLabelFormPopup()
        }
      },
    },
    referenceId: {
      immediate: true,
      handler() {
        if (this.referenceKey === 'FBA') {
          this.getFbaBoxDimensions()
        }
      },
    },
  },
  methods: {
    getFbaBoxDimensions() {
      const result = shipmentLabelService.getFbaBoxDimensions(this.referenceId)
      this.setFbaBoxDimensions(result)
    },
    setFbaBoxDimensions(result) {
      this.pack.count = result.count
      this.pack.length = result.length
      this.pack.width = result.width
      this.pack.height = result.height
      this.pack.weightLb = result.weightLb
      this.pack.weightOz = result.weightOz
      this.pack.description = result.description
    },
    createLabelFormPopup() {
      this.labelFormPopupIns.show()
      this.validationGroup.reset()
      this.senderAddressValidationGroup.reset()
      this.receiverAddressValidationGroup.reset()
      this.customsItemsState = []
    },
    onShownLabelFormPopup() {
      this.accountNo = this.userAccountNo
      this.getAddressByAccount()
      this.getStoreList()
      this.getAmazonAddresses()
      this.toAddress.country = 'USA'
      this.fromAddress.country = 'USA'
    },
    onHiddenLabelFormPopup() {
      this.onClose()
    },
    onClose() {
      this.labelFormPopupIns.hide()
      Object.assign(this.$data, initialState())
      this.customsItemsState = []
      this.$emit('closed')
    },
    getAddressByAccount() {
      this.getStoreList()
      companyService
        .fetchByAccountNo(this.accountNo)
        .then(res => {
          this.companyName = res.data.name
          this.companyId = res.data.id
          this.getCompanyAddresses()
        })
        .catch(() => {
          this.fromAddresses.length = 0
        })
    },
    getCompanyAddresses() {
      this.fromAddresses.length = 0
      commonService
        .fetchAddressesByTenantAndCompany(this.companyId)
        .then(result => {
          const { data } = result
          this.existingAddresses = data
          data.forEach(item => {
            let suiteNumber = ''
            if (item.commonHub?.commonHubType === 'warehouse') {
              suiteNumber = `Suite # ${this.accountNo}`
            }
            let line = item.line1
            line += item.line2 ? ` ${item.line2}` : ''
            line += item.line3 ? ` ${item.line3}` : ''
            const addressStringHtml = `${item.name}<br />${line} ${suiteNumber}<br />${item.city}, ${item.state} ${item.zipcode} ${item.country}`
            const addressString = `${item.name} ${line} ${suiteNumber} ${item.city}, ${item.state} ${item.zipcode} ${item.country}`
            this.fromAddresses.push({
              id: item.id,
              textHtml: addressStringHtml,
              text: addressString,
              country: item.country,
            })
          })
        })
        .then(() => [
          commonService.findPhonesByTenantAndCompany().then(res => {
            this.phones = []
            res.forEach(el => {
              this.phones.push({
                text: el.phone,
                id: el.phone,
              })
            })
          }),
        ])
    },
    getStoreList() {
      companyService
        .fetchCompanyAndStoresByAccountNo(this.accountNo)
        .then(response => {
          this.storeList.length = 0
          this.storeList = response.data.stores.map(store => ({
            key: store.id,
            text: store.name,
            value: store.id,
          }))
          if (this.storeList.length === 1) {
            this.selectedStore = this.storeList[0].value
          }
        })
    },
    async getRatesForPackageBoxes() {
      const response1 = await this.receiverAddressValidationGroup.validate()
      const response2 = await this.senderAddressValidationGroup.validate()
      if (this.boxesDataSource.length === 0) {
        Notify.error('Please add at least one package box')
        return
      }
      if (!this.isNewFromAddress) {
        this.fromAddress.phone = this.selectedPhone
      }
      const payload = {
        companyId: this.companyId,
        fromName: this.companyName,
        shipFromId: this.shipFromId,
        fromAddress: this.fromAddress,
        toAddress: this.toAddress,
        notes: this.notes,
        shipmentParcels: this.boxesDataSource,
        customsDeclarationId: this.customsDeclarationId,
      }

      if (response1.isValid && response2.isValid) {
        shipmentLabelService.getShippingRates(payload).then(res => {
          this.rateList.splice(0, this.rateList.length)
          const rates = res.map(item => ({
            carrier: item.provider,
            currency: item.currency,
            amount: item.amount,
            days: item.estimated_days,
            imageUrl: item.provider_image_75,
            serviceName: item.servicelevel.name,
            rateId: item.objectId,
          }))

          this.rateList.push(...rates)
          this.$refs.rateListPopupRef.instance.show()
        })
      }
    },
    deletePack(e) {
      const index = e.row.rowIndex
      this.boxesDataSource.splice(index, 1)
    },
    async addBox() {
      if ((this.pack.weightLb + this.pack.weightOz) === 0) {
        Notify.warning('Please enter the weight of the package')
        return
      }
      const res = await this.validationGroup.validate()
      if (res.isValid) {
        this.boxesDataSource = [...this.boxesDataSource, this.pack]
        this.pack = {
          count: 1,
          length: null,
          width: null,
          height: null,
          weightLb: 0,
          weightOz: 0,
          description: '',
        }
        this.validationGroup.reset()
      }
    },
    selectRate(e) {
      this.selectedRate = {
        companyId: this.userCompanyId,
        fromName: this.companyName,
        shipFromId: this.shipFromId,
        toAddress: this.toAddress,
        shipmentParcels: this.boxesDataSource,
        rateId: e.itemData.rateId,
        storeId: this.selectedStore,
        notes: this.notes,
        referenceId: this.referenceId,
        referenceKey: this.referenceKey,
      }
    },
    buyRate() {
      shipmentLabelService.purchaseShippingLabel(this.selectedRate).then(result => {
        Notify.success('Shipping label purchased successfully')
        this.$refs.rateListPopupRef.instance.hide()
        this.labelFormPopupIns.hide()
        this.$emit('on-label-form-closed')
        result.forEach(el => {
          window.open(el.labelUrl, '_blank')
        })
      })
    },
    closeRatePopup() {
      this.selectedRate = {}
      this.$refs.rateListPopupRef.instance.hide()
    },
    getAmazonAddresses() {
      commonService.getAmazonAddresses().then(res => {
        const { data } = res
        data.body.forEach((el, i) => {
          data.body[i].text = `${el.countryCode}:${el.code} - (${el.city}/${el.state})`
        })
        this.amazonAddresses.length = 0
        this.amazonAddresses = [...data.body]
      })
    },
    fillAddressFields() {
      const code = this.selectedAmazonAddress.substring(3, 7)
      const address = this.amazonAddresses.filter(el => el.code === code)[0]
      if (address.countryCode === 'US') {
        address.countryCode = 'USA'
      }
      Object.assign(this.toAddress, {
        name: address.name,
        street1: address.line1,
        street2: address.line2,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        country: address.countryCode,
      })
      this.shipToId = null
    },
    onNewFromAddressChanged(e) {
      if (e.value) {
        this.shipFromId = null
      }
    },
    onShipToAddressChange(e) {
      const address = this.existingAddresses.filter(el => el.id === e.selectedItem.id)[0]
      let suiteNumber = ''
      if (address.commonHub.commonHubType === 'warehouse') {
        suiteNumber = address.line2 === '' ? `Suite # ${this.accountNo}` : ` Suite # ${this.accountNo}`
      }
      Object.assign(this.toAddress, {
        name: address.name,
        street1: address.line1,
        street2: (address.line2 || '') + suiteNumber,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        country: address.country,
      })
      this.selectedAmazonAddress = null
    },
    onShipFromIdChanged(e) {
      Object.assign(this.fromAddress, {})
      this.fromAddress.country = e.selectedItem.country
    },
    onCustomItemCreating(e) {
      const { text } = e
      const item = {
        text,
        value: text,
      }
      e.customItem = item
    },
  },
}
</script>

<style lang="scss" scoped>
.px-sm {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
