import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async findBatchParentById(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.findBatchParentById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async getBatchParentsByQuery(filter, pageable) {
    const result = await axios.post(endpoints.outboundShipping.Controller.getBatchParentsByQuery(pageable), filter)
    return result
  },

  async getShipmentPlanStats(id) {
    const result = await axios.get(endpoints.outboundShipping.Controller.getShipmentPlanStats(id))
    return result.data.body
  },

  async getCanTransmitBatch(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getCanTransmitBatch(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getParentItemById(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getParentItemById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async getBatchDataByParentId(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getBatchDataByParentId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getBoxContentFor2DBarcodeAllBatch(id) {
    const response = await axios.get(endpoints.outboundShipping.Controller.getBoxContentFor2DBarcodeAllBatch(id))
    return response.data.body
  },
  async getBoxContentFor2DBarcode(id) {
    const response = await axios.get(endpoints.outboundShipping.Controller.getBoxContentFor2DBarcode(id))
    return {
      data: response.data.body,
    }
  },
  async getPalletBarcode(id) {
    const response = await axios.get(endpoints.outboundShipping.Controller.getPalletBarcode(id))
    return response.data.body
  },
  async createBatchParent(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.outboundShipping.Controller.createBatchParent(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateBatchParent(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateBatchParent(), data)
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async revertShipmentPlanFromBoxingToWorking(id) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.revertShipmentPlanFromBoxingToWorking(id))
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async closeBatchParent(id) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.closeBatchParent(id))
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async updateBatchItemQuantity(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateBatchItemQuantity(), data)
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async updateBatchItemPlan(id, planId, applyAll) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateBatchItemPlan(id, planId, applyAll))
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async setBoxBoxDimensions(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.setBoxBoxDimensions(), data)
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async deleteBatchParent(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.outboundShipping.Controller.deleteBatchParent(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getBatchParentItemsByQuery(filter) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.outboundShipping.Controller.getBatchParentItemsByQuery(), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getBatchParentItems(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getBatchParentItems(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async createBatchParentItem(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.outboundShipping.Controller.createBatchParentItem(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateBatchParentItem(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateBatchParentItem(), data)
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async updateBatchParentItemInfo(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateBatchParentItemInfo(), data)
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async updatePatchItem(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updatePatchItem(), data)
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async deleteBatchParentItem(id, deleteFromAmazon = false) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.outboundShipping.Controller.deleteBatchParentItem(id, deleteFromAmazon))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  /** Amazon Services */
  async submitAmazonInboundShipmentPlan(parentBatchId) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.submitAmazonInboundShipmentPlan(parentBatchId))
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async createAmazonInboundShipment(batchId) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.createAmazonInboundShipment(batchId))
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async consolidateAmazonInboundShipmentPlan(parentBatchId, destination) {
    const result = await axios.put(endpoints.outboundShipping.Controller.consolidateAmazonInboundShipmentPlan(parentBatchId, destination))
    return result
  },
  async createConsolidatedAmazonInboundShipment(data) {
    const result = await axios.post(endpoints.outboundShipping.Controller.createConsolidatedAmazonInboundShipment(), data)
    return result
  },
  async cancelConsolidatingAmazonInboundShipment(shipmentPlanId) {
    const result = await axios.delete(endpoints.outboundShipping.Controller.cancelConsolidatingAmazonInboundShipment(shipmentPlanId))
    return result
  },
  async mergeShipmentPlan(fromPlanId, toPlanId) {
    const result = await axios.put(endpoints.outboundShipping.Controller.mergeShipmentPlan(fromPlanId, toPlanId))
    return result
  },

  async updateAmazonInboundShipment(batchId) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateAmazonInboundShipment(batchId))
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async updateShipFromAddress(batchId, fromAddressId) {
    const result = axios.put(endpoints.outboundShipping.Controller.updateShipFromAddress(batchId, fromAddressId))
    return result
  },

  /*
  * ShipmentBox mappings
  */
  async getAllBoxesByBatchId(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getBatchesByParentId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async findAllBoxesByBatchId(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.findAllBoxesByBatchId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async findAllBoxeDataByBatchId(batchId) {
    const result = await axios.get(endpoints.outboundShipping.Controller.findAllBoxeDataByBatchId(batchId))
    return result.data.body
  },
  async createBox(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.outboundShipping.Controller.createBox(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async deleteFBABatchBoxId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.outboundShipping.Controller.deleteFBABatchBoxId(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async scanInfoBox(data) {
    const result = await axios.put(endpoints.outboundShipping.Controller.scanInfoBox(), data)
    return result
  },

  /*
  * ShipmentBatchParent mappings
  */
  async getFbaBoxItemMapInfoByBoxId(boxId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getFbaBoxItemMapInfoByBoxId(boxId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async assignBatchParentItemToBoxes(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.outboundShipping.Controller.assignBatchParentItemToBoxes(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async assignBatchParentItemToBoxesMap(data) {
    const result = await axios.put(endpoints.outboundShipping.Controller.assignBatchParentItemToBoxesMap(), data)
    return result
  },

  async assignAllBatchParentItemToBox(batchId) {
    const result = await axios.put(endpoints.outboundShipping.Controller.assignAllBatchParentItemToBox(batchId))
    return result
  },

  async estimateTransport(shipmentBatchId, shipmentType) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.estimateTransport(shipmentBatchId, shipmentType))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async getTransportDetails(shipmentBatchId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getTransportDetails(shipmentBatchId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async confirmTransport(shipmentBatchId) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.confirmTransport(shipmentBatchId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async voidFbaShipmentLabel(shipmentBatchId) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.voidFbaShipmentLabel(shipmentBatchId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getFbaShipmentLabels(shipmentBatchId, pageType) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getFbaShipmentLabels(shipmentBatchId, pageType))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async importAmazonShipmentBatch(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.outboundShipping.Controller.importAmazonShipmentBatch(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateSingleBatch(data) {
    const result = await axios.put(endpoints.outboundShipping.Controller.updateSingleBatch(), data)
    return result
  },

}
