<template>
   <div class="statistics-card h-100 col">
      <div class="d-flex flex-column flex-fill h-100">
        <div>
          <strong style="font-size: 18px; font-weight: 900;">
            Shipping Label Actions
          </strong>
        </div>
        <div>
          <small class="d-block">
            Easily buy shipping label at competitive rates and deposit money.
          </small>
        </div>
        <div class="d-flex mt-auto">
          <dx-util-button class="flex-fill mr-1" text="Buy Label" type="success" @click="openLabelForm" />
          <deposit-widget v-if="isOwner" class="flex-fill mr-1" />
          <current-credit-widget class="rounded flex-fill" />
        </div>
      <label-form :component-id="labelFormComponentId" />
    </div>
  </div>
</template>

<script>
import DepositWidget from '@/views/apps/finance/balances/DepositWidget.vue'
import CurrentCreditWidget from '@/views/apps/finance/balances/CurrentCreditWidget.vue'
import { v4 as uuidv4 } from 'uuid'
import LabelForm from '@/views/apps/tools/labels/LabelForm.vue'
import useCurrentUser from '@/libs/app/current-user'
import useStatisticsCardState from './useStatisticsState'

export default {
  components: {
    DepositWidget,
    CurrentCreditWidget,
    LabelForm,
  },
  setup() {
    const {
      openBatchCounts,
    } = useStatisticsCardState()
    const { userRoleName } = useCurrentUser()
    return {
      openBatchCounts,
      userRoleName,
    }
  },
  data() {
    return {
      labelFormComponentId: '',
    }
  },
  computed: {
    isOwner() {
      return this.userRoleName === 'WOWNER' || this.userRoleName === 'SUPERUSER' || this.userRoleName === 'IOWNER'
    },
  },
  methods: {
    formatNumber(number) {
      if (typeof number !== 'number' || Number.isNaN(number)) {
        return '0'
      }
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
    openLabelForm() {
      this.labelFormComponentId = uuidv4()
    },
  },
}
</script>

<style lang="scss" scoped>

.statistics-card {
  background-color: rgb(197, 235, 208);
  color: rgb(35, 38, 41);
  display: flex;
  align-items: start;
  padding: 12px;
  border-radius: 5px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
}

@keyframes pulse {
  0% {
    border-color: rgb(215, 2, 119);
    border-radius: 2px;
  }
  50% {
    border-color: rgb(138, 29, 125);
    border-radius: 2px;
  }
  100% {
    border-color: rgb(215, 2, 119);
    border-radius: 2px;
  }
}

.pulsing-border {
  border: 2px solid;
  padding: 12px;
  background-color: rgba(232, 20, 66, 0.26) !important;
  color: rgb(211, 181, 213);
  animation: pulse 3s infinite; /* Adjust the duration as needed */
}

@media screen and (max-width: 1280px) {
 .statistics-card {
    width: 100%;
 }
}

</style>
