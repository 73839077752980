<template>
  <div v-if="disputesCount > 0 || isMerchantConnect">
    <div class="card card-psh mb-0">
      <div class="bg-light-danger rounded-top p-1">
        <div class="psh-header mb-0 d-flex align-items-center px-2">
          <div class="psh-title border-0">
            <p-icon name="bi-exclamation-triangle-fill" size="48px" color="danger" />
          </div>
          <div class="d-flex flex-column">
            <h4 class="text-danger card-title mb-25">
              Disputes
            </h4>
            <p class="card-text mb-0">
              Disputes list in last 60 days
            </p>
          </div>
          <div class="ml-auto">
            <dx-util-button
              type="default"
              icon="pulldown"
              @click="onClickReloadDisputes"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <dx-util-scroll-view show-scrollbar="onHover" :height="scroolHeightPartial(6)">
          <dx-data-grid
            id="disputesGrid"
            ref="disputesGridRef"
            :data-source="DisputesDataSource"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :customize-columns="customizeColumns"
            @option-changed="onOptionChanged"
            @initialized="onInitialized"
            @content-ready="onContentReady"
          >
            <dx-column data-field="customer.firstname" caption="Firstname" />
            <dx-column data-field="customer.lastname" caption="Lastname" />
            <dx-column data-field="customer.company" caption="Company" />
            <dx-column data-field="amount" :format="{ type: 'currency', precision: 2 }" />
            <dx-column data-field="reason" />
            <dx-column data-field="status" />
            <dx-column data-field="created_at" data-type="date" format="yyyy-MM-dd hh:mm" />
          </dx-data-grid>
        </dx-util-scroll-view>
      </div>
    </div>
  </div>
</template>

<script>
import { DisputesDataSource } from '@/views/apps/account/merchant-connect/components/disputes.store'
import { TransactionDataSource } from '@/views/apps/account/merchant-connect/components/transaction.store'
import GridBase from '@core/dev-extreme/mixins/grid/base'

export default {
  mixins: [GridBase],
  props: {
    isMerchantConnect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DisputesDataSource,
    }
  },
  computed: {
    disputesCount() {
      const count = DisputesDataSource.totalCount() || 0
      if (count < 1) {
        this.$emit('emit-disputes-count', count)
      }
      return count
    },
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        TransactionDataSource.pageIndex(0)
        TransactionDataSource.reload()
      }
    },
    loadData() {
      DisputesDataSource.load()
    },
    onClickReloadDisputes() {
      DisputesDataSource.reload()
    },
    scroolHeightPartial(x) {
      return (window.innerHeight - 540) / x
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
