/**
 * Enum representing different merchant platforms.
 * @enum {Object}
 * @readonly
 */
const MerchantPlatformsEnum = Object.freeze({
  WALMART: {
    key: 'WALMART',
    value: 'Walmart',
    text: 'Walmart',
    channels: [
      { key: 'WFS', text: 'WFS' },
    ],
  },
  AMAZONSP: {
    key: 'AMAZONSP',
    value: 'AmazonSP',
    text: 'Amazon',
    channels: [
      { key: 'FBA', text: 'FBA' },
      { key: 'MFN', text: 'MFN' },
    ],
  },
  AMAZON: {
    key: 'AMAZON',
    value: 'Amazon',
    text: 'Amazon',
    channels: [
      { key: 'FBA', text: 'FBA' },
      { key: 'MFN', text: 'MFN' },
    ],
  },
  SHOPIFY: {
    key: 'SHOPIFY',
    value: 'Shopify',
    text: 'Shopify',
    channels: [
      { key: 'FBM', text: 'fbm' },
    ],
  },
  ETSY: {
    key: 'ETSY',
    value: 'Etsy',
    text: 'Etsy',
    channels: [
      { key: 'FBM', text: 'fbm' },
    ],
  },
  EBAY: {
    key: 'EBAY',
    value: 'EBay',
    text: 'Ebay',
    channels: [
      { key: 'FBM', text: 'fbm' },
    ],
  },
  getChannels: query => {
    if (query) {
      return Object.values(MerchantPlatformsEnum).find(i => i.value === query).channels
    }
    return []
  },
})

export default MerchantPlatformsEnum
