<template>
  <div>
    <dx-util-button :id="`deposit-${componentId}`" class="w-100 h-100" text="Deposit" type="default" @click="openDepositPopup" />
    <dx-util-popover :width="300" :visible="false"
      :target="`#deposit-${componentId}`" position="bottom"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
    >
      Click to deposit money in your account.
    </dx-util-popover>
    <dx-util-popup
      ref="depositPopupRef"
      :hide-on-outside-click="false"
      :show-title="true"
      :drag-enabled="false"
      :width="calculateWidth"
      height="auto"
      position="center"
      title="Deposit To Balance"
      @hidden="onHiddenDepositPopup"
    >
      <div class="pl-half mb-2">
        <p-icon name="bi-info-circle" class="mr-half" />
        Please select a payment method to fund your account.
      </div>
      <dx-data-grid
        id="creditCardGrid"
        ref="creditCardGridRef"
        class="mb-2"
        :data-source="creditCards"
        height="auto"
        :selection="{ mode: 'single' }"
        no-data-text="Please add credit card to deposit money by using Account > My Company menu."
        @selection-changed="onSelectionChanged"
      >
        <dx-column data-field="nickname" caption="Card Details" :allow-sorting="false" />
        <dx-column data-field="cardExp" caption="Card Expiry" :width="100" :allow-sorting="false" />
        <dx-column data-field="isDefault" caption="Is Default" cell-template="isDefaultTemplate" :width="100" :allow-sorting="false" />
        <dx-column data-field="itemAction" caption="Use This" cell-template="useThisTemplate" alignment="center" :width="100" :allow-sorting="false" />
        <template #isDefaultTemplate="{data}">
          <p-icon :name="data.value ? 'feather-check' :'none'" :class="data.value ? 'text-success' :''" />
        </template>
        <template #useThisTemplate="{data}">
          <div>
            <p-icon :name="data.data.id === selectedCreditCardId ? 'feather-disc' : ''" :color="data.data.id === selectedCreditCardId ? 'success' : ''" />
          </div>
        </template>
      </dx-data-grid>
      <dx-util-validation-group ref="depositValidationGroup">
        <div class="px-1 mb-1">
          <dx-util-text-area v-model="balanceRequest.notes" label="Notes" />
        </div>
        <div class="d-flex justify-content-end">
          <div class="px-1 col-12 col-sm-6 mb-1" style="max-width: 300px;">
            <dx-util-number-box v-model="balanceRequest.amount" label="Amount" format="$ #,##0.##">
              <dx-util-validator>
                <dx-util-required-rule message="Amount is required" />
                <dx-util-range-rule :min="10" message="Amount must be greater than $10" />
              </dx-util-validator>
            </dx-util-number-box>
            <table class="my-half text-warning">
              <tr>
                <td>Payment Method</td>
                <td class="text-uppercase">
                  {{ selectedMethod }}
                </td>
              </tr>
              <tr>
                <td>Deposit Amount</td>
                <td class="text-right">
                  {{ getCurrencyFormat(balanceRequest.amount) }}
                </td>
              </tr>
              <tr class="font-weight-thin">
                <td>Processing Fee (%3)</td>
                <td class="text-right">
                  {{ getCurrencyFormat(balanceRequest.amount * 0.03) }}
                </td>
              </tr>
              <tr class="font-weight-bolder">
                <td>Total Payment</td>
                <td class="text-right">
                  {{ getCurrencyFormat(balanceRequest.amount * 1.03) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <dx-util-button text="Cancel" type="danger" class="mr-1" style="min-width: 100px;" @click="closeDepositPopup" />
          <dx-util-button text="Save" type="success" style="min-width: 100px;" @click="depositToBalance" />
        </div>
      </dx-util-validation-group>
    </dx-util-popup>
  </div>
</template>

<script>
import { BalanceTypesEnum } from '@/enums'
import customerBalanceService from '@/http/requests/finance/customerBalanceService'
import { v4 as uuidv4 } from 'uuid'
import staxService from '@/http/requests/external/stax'
import useCurrentUser from '@/libs/app/current-user'
import { Notify } from '@/@robustshell/utils'
import { currencyFormatter } from '@core/utils/filter'
import { useWindowSize } from '@vueuse/core'
import useCustomerBalance from './useCustomerBalance'

export default {
  data() {
    return {
      currentBalance: 99.00,
      balanceRequest: {
        companyId: 0,
        cardId: null,
        amount: 0,
        balanceType: BalanceTypesEnum.DEPOSIT.value,
        initiatedBy: '',
        notes: '',
      },
      creditCards: [],
      selectedCreditCardId: '',
      selectedMethod: '',
    }
  },
  computed: {
    depositPopup() {
      return this.$refs.depositPopupRef.instance
    },
    componentId() {
      return uuidv4()
    },
    calculateWidth() {
      let adjustedWidth = ''
      if (this.windowWidth < 400) {
        adjustedWidth = '95vw'
      } if (this.windowWidth >= 400 && this.windowWidth <= 1200) {
        adjustedWidth = '80vw'
      } if (this.windowWidth > 1200 && this.windowWidth <= 1400) {
        adjustedWidth = '60vw'
      } if (this.windowWidth > 1400) {
        adjustedWidth = '40vw'
      }
      return adjustedWidth
    },
  },
  setup() {
    const { userCompanyId } = useCurrentUser()
    const { currentCredit, getCurrentBalance } = useCustomerBalance()
    const { width: windowWidth } = useWindowSize()

    return {
      userCompanyId, currentCredit, getCurrentBalance, windowWidth,
    }
  },
  mounted() {
    this.getCreditCards()
  },
  methods: {
    openDepositPopup() {
      this.depositPopup.show()
    },
    closeDepositPopup() {
      this.depositPopup.hide()
      this.resetForm()
    },
    resetForm() {
      this.$refs.depositValidationGroup.instance.reset()
      this.balanceRequest.notes = ''
    },
    async getCreditCards() {
      const response = await staxService.getAllCreditCardsByCompanyId(this.userCompanyId)
      if (response && response.length > 0) {
        const defaultCard = response.find(c => c.isDefault)
        this.selectedCreditCardId = defaultCard.id
        this.selectedMethod = defaultCard.method
        this.creditCards.length = 0
        this.creditCards = [...response]
      }
    },
    depositToBalance(e) {
      if (this.selectedCreditCardId === '') {
        Notify.warning('Please select a payment method to complete the form.')
      }
      const validationResult = e.validationGroup.validate()
      if (validationResult.isValid) {
        this.balanceRequest.companyId = this.userCompanyId
        this.balanceRequest.cardId = this.selectedCreditCardId
        customerBalanceService.createBalanceTransaction(this.balanceRequest).then(() => {
          this.getCurrentBalance(this.userCompanyId, this.balanceType)
          this.closeDepositPopup()
          e.validationGroup.reset()
          this.$emit('on-emit-deposit-change')
        })
      }
    },
    onHiddenDepositPopup() {
      this.closeDepositPopup()
    },
    onSelectionChanged(e) {
      this.selectedCreditCardId = e.selectedRowsData[0].id
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data)
    },
  },
}
</script>

<style lang="scss" scoped>
  td {
    padding: 0 10px;
  }
</style>
