export const awaiter = delay => new Promise(resolve => setTimeout(resolve, delay || 2000))

export const isLocation = (str, matchCount = 1) => {
  const regex = /-/g
  const match = str.match(regex)
  return (match && match.length > matchCount)
}

export const updateCountryCode = inputString => {
  const uppercasedString = inputString.toUpperCase()
  if (uppercasedString === 'USA') {
    return 'US'
  }
  return inputString
}

export const generateRandomString = (length = 10) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  for (let i = length; i > 0; i -= 1) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export const buildUrl = (url, params) => {
  const qs = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${url}?${qs}`
}
