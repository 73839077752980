import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.externalAmazon.controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async findByStoreId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.externalAmazon.controller.findByStoreId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.externalAmazon.controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.externalAmazon.controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchSellerInventoryData(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.externalAmazon.controller.fetchSellerInventoryData(data))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getClientSecret() {
    const result = await axios.get(endpoints.externalAmazon.controller.getClientSecret())
    return result.data.body
  },
}
