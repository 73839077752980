import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getFbmStoreSummary(data, pageable) {
    const result = await axios.post(endpoints.fbm.Controller.getFbmStoreSummary(pageable), data)
    return result.data.body
  },
  async getFbmOrders(data, pageable) {
    const result = await axios.post(endpoints.fbm.Controller.getFbmOrders(pageable), data)
    return result
  },
  async getOrderAllInfo(data) {
    const result = await axios.post(endpoints.fbm.Controller.getOrderAllInfo(), data)
    return result.data.body
  },
  async findFbmOrderById(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.findFbmOrderById(fbmOrderId))
    return result.data.body
  },
  async getItems(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.getItems(fbmOrderId))
    return result.data.body
  },
  async importManualOrders(warehouseId, storeId, data) {
    const result = await axios.post(endpoints.fbm.Controller.importManualOrders(warehouseId, storeId), data)
    return result
  },
  async uploadManualOrders(warehouseId, storeId, data) {
    const result = await axios.put(endpoints.fbm.Controller.importManualOrders(warehouseId, storeId), data)
    return result
  },
  async deleteManualOrders(data) {
    const result = await axios.post(endpoints.fbm.Controller.deleteManualOrders(), data)
    return result
  },
  async downloadTemplate() {
    const result = await axios.get(endpoints.fbm.Controller.downloadTemplate())
    return result.data
  },
  async pullStoreOrders(storeId) {
    const result = await axios.get(endpoints.fbm.Controller.pullStoreOrders(storeId))
    return result.data.body
  },
  // ADDRESSES
  async getWarehouseAddress() {
    const result = await axios.get(endpoints.fbm.Controller.getWarehouseAddress())
    return result
  },
  async updateShippingAddress(fbmOrderId, data) {
    const result = await axios.put(endpoints.fbm.Controller.updateShippingAddress(fbmOrderId), data)
    return result
  },
  async getCustomerAddress(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.getCustomerAddress(fbmOrderId))
    return result
  },
  // CHARGES
  async getAllCharges(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.getAllCharges(fbmOrderId))
    return result.data.body
  },
  async createCharges(data) {
    const result = await axios.post(endpoints.fbm.Controller.createCharges(), data)
    return result.data.body
  },
  async deleteCharge(chargeId) {
    const result = await axios.delete(endpoints.fbm.Controller.deleteCharge(chargeId))
    return result.data.body
  },
  async deleteChargesInBulk(data) {
    const result = await axios.post(endpoints.fbm.Controller.deleteChargesInBulk(), data)
    return result.data.body
  },
  async getDefaultCharges(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.getDefaultCharges(fbmOrderId))
    return result.data.body
  },
  // LABELS
  async purchaseLabel(data) {
    const result = await axios.post(endpoints.fbm.Controller.purchaseLabel(), data)
    return result
  },
  async getShippingLabels(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.getShippingLabels(fbmOrderId))
    return result.data.body
  },
  async getShippingLabelRates(data) {
    const result = await axios.post(endpoints.fbm.Controller.getShippingLabelRates(), data)
    return result.data.body
  },
  async deleteShippingLabel(labelId) {
    const result = await axios.delete(endpoints.fbm.Controller.deleteShippingLabel(labelId))
    return result
  },
  async saveCustomerShippingLabel(data) {
    const result = await axios.post(endpoints.fbm.Controller.saveCustomerShippingLabel(), data)
    return result
  },
  async buyBulkShippingLabel(data) {
    const result = await axios.post(endpoints.fbm.Controller.buyBulkShippingLabel(), data)
    return result.data.body
  },
  async updateBulkShippingLabel(data) {
    const result = await axios.put(endpoints.fbm.Controller.updateBulkShippingLabel(), data)
    return result.data.body
  },
  async makeBulkReservation(data) {
    const result = await axios.post(endpoints.fbm.FbmItemReservationController.makeBulkReservation(), data)
    return result.data.body
  },
  async cancelBulkReservation(data) {
    const result = await axios.post(endpoints.fbm.FbmItemReservationController.cancelBulkReservation(), data)
    return result.data.body
  },
  async buyLabelAndMarkAsShipped(data) {
    const result = await axios.post(endpoints.fbm.Controller.buyLabelAndMarkAsShipped(), data)
    return result.data.body
  },
  async downloadLabelsInBulk(data) {
    const result = await axios.post(endpoints.fbm.Controller.downloadLabelsInBulk(), data, { responseType: 'blob' })
    return result
  },
  async markAsShippedInBulk(data) {
    const result = await axios.post(endpoints.fbm.Controller.markAsShippedInBulk(), data)
    return result.data.body
  },
  async checkItemsReservations(data) {
    const result = await axios.post(endpoints.fbm.Controller.checkItemsReservations(), data)
    return result.data.body
  },
  async checkItemsReservationData(data) {
    const result = await axios.post(endpoints.fbm.Controller.checkItemsReservationData(), data)
    return result.data.body
  },
  async getCustomCarriers() {
    const result = await axios.get(endpoints.fbm.Controller.getCustomCarriers())
    return result.data.body
  },
  async createSellerCentralShippingLabel(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.createSellerCentralShippingLabel(fbmOrderId))
    return result.data.body
  },
  async getAmazonShippingLabelDocs(fbmOrderId, labelId) {
    const result = await axios.get(endpoints.fbm.Controller.getAmazonShippingLabelDocs(fbmOrderId, labelId),
      { responseType: 'blob' })
    return result
  },
  // ADMIN
  async matchAwaitingItems(storeId) {
    const result = await axios.get(endpoints.fbm.Controller.matchAwaitingItems(storeId))
    return result.data.body
  },
  async reserveAwaitingItems(storeId) {
    const result = await axios.get(endpoints.fbm.Controller.reserveAwaitingItems(storeId))
    return result.data.body
  },
  // DIMENSIONS
  async getBoxDimensions(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.getBoxDimensions(fbmOrderId))
    return result.data.body
  },
  // BOX
  async createBoxItems(labelId, data) {
    const result = await axios.post(endpoints.fbm.Controller.createBoxItems(labelId), data)
    return result
  },
  async getRemainingReservedItems(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.getRemainingReservedItems(fbmOrderId))
    return result.data.body
  },
  async deleteBoxItems(labelId) {
    const result = await axios.delete(endpoints.fbm.Controller.deleteBoxItems(labelId))
    return result.data.body
  },
  async markAsShipped(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.markAsShipped(fbmOrderId))
    return result
  },
  async cancelShipment(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.cancelShipment(fbmOrderId))
    return result
  },
  async reopenShipment(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.Controller.reopenShipment(fbmOrderId))
    return result
  },
  // OTHERS
  async getOrders(data, pageable) {
    const result = await axios.post(endpoints.newFbm.Controller.getOrders(pageable), data)
    return result.data.body
  },
  async getOrderReservedItems(fbmOrderId) {
    const result = await axios.get(endpoints.fbm.FbmItemReservationController.getOrderReservedItems(fbmOrderId))
    return result.data.body
  },
  // PICK PACK LIST
  async createPickBatch(data) {
    const result = await axios.post(endpoints.fbm.Controller.createPickBatch(), data)
    return result
  },
  async getPickBatches() {
    const result = await axios.get(endpoints.fbm.Controller.getPickBatches())
    return result.data.body
  },
  async getPickBatch(batchId) {
    const result = await axios.get(endpoints.fbm.Controller.getPickBatch(batchId))
    return result.data.body
  },
  async deletePickBatch(batchId) {
    const result = await axios.delete(endpoints.fbm.Controller.deletePickBatch(batchId))
    return result
  },
  async deletePickBatchItem(itemId) {
    const result = await axios.delete(endpoints.fbm.Controller.deletePickBatchItem(itemId))
    return result
  },
  async updatePickBatchItem(data) {
    const result = await axios.put(endpoints.fbm.Controller.updatePickBatchItem(), data)
    return result
  },
  // WEB ORDERS
  async getWebOrders(data) {
    const result = await axios.post(endpoints.fbm.Controller.getWebOrders(), data)
    return result.data.body
  },
  async refreshOrders(data) {
    const result = await axios.post(endpoints.fbm.Controller.refreshOrders(), data)
    return result
  },
  async pullOrder(storeId, orderId) {
    const result = await axios.post(endpoints.fbm.Controller.pullOrder(storeId, orderId))
    return result
  },
}
